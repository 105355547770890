import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#F8F8F8",
      dark: "#D8DFDE",
      darker: "#D2D2D2",
      gray: "#B2B4B4",
      gray2: "#656969",
      gray3: "#3C4242",
      black: "#0C0C0C", //#160F1A
      link: "#336084",
    },
    secondary: {
      main: "#B19FBA", //#110720
      lighter: "#D8CFDD",
      dark: "#634075",
      darker: "#28104B",
      darkest: "#160F1A",
    },
    error: { main: "#C2434B" },
    success: { main: "#6FCF97" },
    warning: { main: "#F6CD66", darker: "#F0AB00", yellow: "#C4D600" },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontWeight: 400,
    lineHeight: 1.3,
    color: "#F8F8F8",
    h1: {
      fontSize: "2rem",
      "@media (max-width: 1200px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.25rem",
      },
      fontWeight: 300,
      marginBottom: "0.5rem",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 300,
      marginBottom: "1rem",
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.4,
      marginBottom: "0.5rem",
    },
    body1: {
      fontWeight: 300,
      fontSize: "1rem",
      lineHeight: "1.75rem",
      "@media (max-width: 600px)": {
        fontSize: "0.75rem",
        lineHeight: "1.125rem",
      },
    },
    body2: {
      fontSize: "0.875rem",
      // lineHeight: "1.5rem",
      fontWeight: 300,
      lineHeight: 1.4,
    },
    body3: {
      fontSize: "0.75rem",
      lineHeight: 1.3,
      marginBottom: "0 !important",
      fontWeight: 400,
      // lineHeight: 1.3,
    },
    body4: {
      fontSize: "0.625rem",
      lineHeight: 1.3,
      marginBottom: "0 !important",
      fontWeight: 400,
      // lineHeight: 1.3,
    },
    // fontSize: 16,
    // fontWeight: 400,
    // lineHeight: 1.6,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        // root: {
        //   "&:hover": {
        //     color: "#F8F8F8 !important",
        //   },
        // },
        body1: {
          "&:not(:last-child)": {
            marginBottom: "1rem",
          },
        },
        body2: {
          "&:not(:last-child)": {
            marginBottom: "0.5rem",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // marginTop: "-1px",
          padding: "0 !important",
          background: "none !important",
          borderRadius: "0.75rem !important",
          overflow: "hidden",
          border: "0.5px solid rgba(248, 248, 248, 0.20)",
          boxSizing: "border-box",
          height: "3.5rem",
          boxShadow: "0px 0px 16px 1px rgba(123, 33, 171, 0.35)",
          transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          position: "relative",
          "&:after, &:before": {
            border: "none !important",
          },
          "&:after": {
            position: "absolute",
            zIndex: -1,
            borderRadius: "0.75rem !important",
            overflow: "hidden",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(248, 248, 248, 0.45)",
            mixBlendMode: "overlay",
            transform: "scaleX(1) !important",
          },
          "&.Mui-disabled:after": {
            background: "rgba(248, 248, 248, 0.1)",
          },
          "&.Mui-disabled": {
            boxShadow: "none",
          },
          "&.Mui-focused": {
            transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            boxShadow: "0px 0px 200px 24px rgba(97, 25, 141, 0.55)",
          },
          "&.Mui-focused input": {
            color: "#C4D0CD",
            backdropFilter: "blur(16px)",
          },
        },
        multiline: {
          height: "auto",
        },
        input: {
          padding: "1rem 1rem 0.25rem 1rem !important",
          // borderRadius: "0.75rem !important",
          // overflow: "hidden",
          background: "none",
          color: "#D2D2D2",
          width: "100%",
          height: "3.5rem",
          boxSizing: "border-box",
          backdropFilter: "blur(4px)",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #28104B inset !important",
            WebkitTextFillColor: "#C4D0CD !important",
            caretColor: "#C4D0CD !important",
            borderTopLeftRadius: "initial !important",
            borderTopRightRadius: "initial !important",
            background: "none !important",
            backgroundColor: "transparent !important",
          },
          "&.Mui-disabled": {
            color: "#C4D0CD",
            WebkitTextFillColor: "#C4D0CD !important",
            backdropFilter: "none",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // marginBottom: "0 !important",
          color: "#C4D0CD",
          transform: "translate(17px, 17px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(17px, 7px) scale(0.75)",
          },
          "&.Mui-disabled": {
            color: "#C4D0CD",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          border: "none",
          background: "none",
          color: "#fff",
          textTransform: "none",
          fontSize: "0.875rem",
          fontWeight: 300,
          // minHeight: "calc(3.5rem - 1px)",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          padding: "0 1rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          fontWeight: 300,
          ...(ownerState.variant === "contained" &&
            ownerState.size === "medium" && {
              minHeight: "2.5rem",
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.size === "large" && {
              minHeight: "3.5rem",
              "@media (max-width: 600px)": {
                minHeight: "3rem",
              },
            }),
        }),
        contained: {
          padding: "0.5rem 1rem",
          lineHeight: "1rem",
          borderRadius: "12.5rem",
          background:
            "linear-gradient(85deg, #28104B 6.11%, #521D5E 44.4%, #502267 50.65%, #28104B 96%)",
          backgroundSize: "180% 100%",
          backgroundPosition: "0% 0%",
          boxShadow: "0px 0px 16px 1px rgba(123, 33, 171, 0.35)",
          backdropFilter: "blur(4px)",
          color: "#fff",
          fontSize: "0.875rem",
          letterSpacing: "0.035rem",
          minWidth: "7.5rem",
          transition:
            "box-shadow 400ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, background-position 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          "&.Mui-focusVisible, &:hover": {
            transition:
              "box-shadow 400ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, background-position 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            boxShadow: "0px 0px 100px 24px rgba(97, 25, 141, 0.35)",
            backgroundPosition: "80% 0%",
          },
          "&.Mui-disabled": {
            color: "#fff",
            background:
              "linear-gradient(85deg, rgba(40, 16, 75, 0.45) 11.06%, rgba(82, 29, 94, 0.45) 80.03%, rgba(80, 34, 103, 0.45) 91.18%)",
          },
        },
        outlined: {
          padding: "0.5rem 1rem",
          lineHeight: "1rem",
          borderRadius: "12.5rem",
          border: "0.5px solid rgba(255, 255, 255, 0.25)",
          color: "#fff",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "-0.5rem",
        },
        label: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          position: "relative",
          overflow: "hidden",
          background: "none",
          "&:after": {
            content: "''",
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "#D8DADA",
            mixBlendMode: "overlay",
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "1rem",
          "@media (max-width: 600px)": {
            fontSize: "0.875rem",
          },
          fontWeight: "300 !important",
          "&.Mui-disabled, &.Mui-completed": {
            color: "#F8F8F8 !important",
            fontSize: "0.875rem",
            "@media (max-width: 600px)": {
              fontSize: "0.75rem",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0.25rem",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
          border: "0.5px solid rgba(255, 255, 255, 0.20)",
          backdropFilter: "blur(16px)",
          background: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // transition: "all 200ms ease-in-out",
          "&:hover": {
            background: "rgba(255, 255, 255, 0.15)",
          },
          "&.Mui-selected": {
            background: "linear-gradient(90deg, #A30057 1.22%, #6F1D9A 78.41%)",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          lineHeight: 1.4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0.875rem",
          background:
            "linear-gradient(90deg, #28104B 5%, #521D5E 80%, #502267 95%)",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: "solid 1px",
          borderImage:
            "linear-gradient(to right,rgba(254,254,254,0),rgba(254,254,254,0.53),rgba(254,254,254,0)) 1 0 0 0",
        },
      },
    },
  },
});

export default theme;
