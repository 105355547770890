import { useState, useEffect } from "react";

const useWindowAspectRatio = () => {
  const [aspectRatio, setAspectRatio] = useState(
    window.innerWidth / window.innerHeight
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateAspectRatio = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      const ratio = width / height;
      setAspectRatio(ratio);
      setWindowWidth(width);
      setWindowHeight(height);
      // console.log("windowWidth", width);
    };

    window.addEventListener("resize", updateAspectRatio);

    updateAspectRatio(); // Initial check

    return () => {
      window.removeEventListener("resize", updateAspectRatio);
    };
  }, []);

  return { aspectRatio, windowWidth, windowHeight };
};

export default useWindowAspectRatio;
