import React from "react";
// import { Context } from "context";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import ChooseTherapeuticAreas from "components/therapeutic-areas/ChooseTherapeuticAreas";
import useUserTracking from "utils/useTracking";
// import { CheckUserAuthenticated } from "components/common";

const Homepage = () => {
  // const user = useContext(Context);

  useUserTracking({ page: "homepage" });

  return (
    // <CheckUserAuthenticated shouldBeLoggedIn={true}>
    <LoggedInTemplate>
      <ChooseTherapeuticAreas />
    </LoggedInTemplate>
    // </CheckUserAuthenticated>
  );
};

export default Homepage;
