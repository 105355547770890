import { useEffect } from "react";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import axios from "axios";
import config from "utils/config";
import { useUtag } from "hooks/useUtag";

const getEpochTimeInSeconds = () => {
  const now = new Date();
  const epochTimeInSeconds = Math.floor(now.getTime() / 1000);
  return epochTimeInSeconds;
};

const useUserTracking = (params = {}) => {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();

  const utag = useUtag();

  const userTrack = async (newParams = {}) => {
    const paramsAPI = {
      mdm: auth()?.mdm,
      url: window.location.href,
      ...params,
      ...newParams,
    };

    // Explicitly handle page views
    const pageName =
      paramsAPI.page === "homepage"
        ? "Home"
        : paramsAPI.page ||
          paramsAPI.brand ||
          paramsAPI.therapeutic_area_indication ||
          paramsAPI.therapeutic_area ||
          "";

    const paramsUtag = {
      page_type: "react",
      page_name: pageName,
      page_section: paramsAPI.therapeutic_area_indication || "", //<site section of the current page>
      page_subsection: paramsAPI.content_type || "", //<subsection of the current page>
      page_therapy_area:
        paramsAPI.page === "homepage"
          ? "general"
          : paramsAPI.therapeutic_area || "",
      page_brand: paramsAPI.brand || "",
      visitor_azid: paramsAPI.mdm || "",
      visitor_time: getEpochTimeInSeconds(),
      visitor_auth_status: paramsAPI.mdm ? "logged in" : "anonymous",
      core_target_country: "Romania",
      core_language: "RO-ro",
      core_target_audience: "HCP",
      ...(paramsAPI.video_name
        ? {
            video_name: paramsAPI.video_name,
            video_id: paramsAPI.video_id,
          }
        : {}),
    };
    // console.log("tealium_utag PARAMS", paramsUtag);
    // console.log("tealium_utag", utag);

    utag.view({ ...paramsUtag });
    // utag.track("view", { ...paramsUtag });

    // if the user is logged in then send the /track-user request to API
    if (paramsAPI.mdm) {
      console.log("API /track-user", paramsAPI);

      await axios.post(`${config.API_URL}/track-user`, paramsAPI, {
        headers: {
          Authorization: authHeader(),
        },
      });
    }
  };

  useEffect(() => {
    if (!params.stop) {
      userTrack();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { userTrack };
};

export default useUserTracking;
