import React, { useState, useEffect, createContext, useContext } from "react";

const EmptyUtag = { link: () => undefined, view: () => undefined };

const UtagContext = createContext(EmptyUtag);

export const useUtag = () => useContext(UtagContext);

export const UtagProvider = ({ children }) => {
  const [utag, setUtag] = useState(EmptyUtag);

  useEffect(() => {
    if (window.utag) {
      setUtag(window.utag);
      return;
    }
    let i = 0;
    const interval = setInterval(() => {
      if (window.utag) {
        setUtag(window.utag);
        clearInterval(interval);
        return;
      }
      i = i + 1;
      if (i > 50) {
        clearInterval(interval);
      }
    }, 200);
    return () => clearInterval(interval);
  }, []);

  return <UtagContext.Provider value={utag}>{children}</UtagContext.Provider>;
};
