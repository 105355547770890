import React, { useContext, useState } from "react";
import { useSignOut } from "react-auth-kit";
import {
  // Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  IconButton,
  Typography,
  Box,
  Stack,
  Drawer,
} from "@mui/material";
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import theme from "utils/theme";
import { LogoAZnextGen, Menu, MenuMobile } from "components/common";
import config from "utils/config";
import axios from "axios";
import appContext from "utils/context";
import { useQueryClient } from "@tanstack/react-query";

const Header = () => {
  const signOut = useSignOut();
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  // console.log(authHeader());
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isWhiteContentPage } = useContext(appContext);

  const signOutAll = async () => {
    signOut();
    // await queryClient.invalidateQueries();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  const signOutHandler = async () => {
    try {
      const responseLogout = await axios.post(
        `${config.API_URL}/logout`,
        null,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );
      if (
        responseLogout.data.message === "You have been logged out successfully"
      ) {
        await signOutAll();
      }
      // navigate("/");
      // console.log(response);
    } catch (err) {
      if (err.response.status !== 500) {
        await signOutAll();
      } else {
        alert(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
        console.error(err);
      }
      await signOutAll();
    }
  };

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: 2,
        background: isWhiteContentPage
          ? "linear-gradient(206deg, rgb(42,17,80,0.9) 23.8%, rgb(41,14,47,0.9) 60.19%, rgb(41,18,54,0.9) 99.55%)"
          : "rgba(248, 248, 248, 0.10)",
        boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.10)",
        backdropFilter: "blur(4px)",
        padding: {
          xs: theme.spacing(1.25, 1.5, 1.25, 1),
          md: theme.spacing(1.25, 1),
          lg: theme.spacing(1.25, 4),
        },
      }}
    >
      <Stack direction="row" columnGap={2} alignItems="center">
        <Box
          sx={{
            paddingRight: {
              lg: 5,
            },
          }}
        >
          <Button onClick={() => navigate("/")}>
            <LogoAZnextGen height={18} />
          </Button>
        </Box>
        <Box flexGrow={1} sx={{ display: { xs: "none", sm: "block" } }}>
          <Menu />
        </Box>
        <Box
          flexGrow={1}
          textAlign="right"
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <IconButton
            variant="text"
            onClick={() => setOpenMobileMenu(!openMobileMenu)}
          >
            {openMobileMenu ? (
              <XMarkIcon width={24} />
            ) : (
              <Bars3Icon width={24} />
            )}
          </IconButton>
          <Drawer
            anchor="top"
            open={openMobileMenu}
            onClose={() => setOpenMobileMenu(false)}
            transitionDuration={{
              enter: 300,
              exit: 150,
            }}
            hideBackdrop
            ModalProps={{
              sx: { top: "60px !important" },
            }}
            PaperProps={{
              sx: {
                marginTop: "60px",
                background: isWhiteContentPage
                  ? "rgba(59, 16, 113, 0.70)"
                  : "rgba(248,248,248,0.05)",
                backdropFilter: "blur(90px)",
                padding: theme.spacing(1, 3, 3, 3),
              },
            }}
          >
            <Stack direction="column" gap={2}>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography sx={{ fontWeight: 500 }}>
                      {auth().first_name} {auth().last_name}
                    </Typography>
                  </Box>
                  <Box sx={{ marginRight: -1 }}>
                    <IconButton variant="text" onClick={signOutHandler}>
                      <ArrowRightOnRectangleIcon width={18} />
                    </IconButton>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    marginTop: 1,
                    marginBottom: 0.5,
                    width: "100%",
                    height: "1px",
                    opacity: 0.6,
                    background:
                      "linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 0.52) 50%, rgba(254, 254, 254, 0) 100%)",
                  }}
                />
              </Box>
              <MenuMobile closeDrawer={() => setOpenMobileMenu(false)} />
            </Stack>
          </Drawer>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <Typography>
            Bine ai venit, {auth().first_name} {auth().last_name}
          </Typography>
        </Box>
        {/* <Box sx={{ display: { xs: "none", sm: "block", lg: "none" } }}>
          <Typography>
            {auth().first_name} {auth().last_name}
          </Typography>
        </Box> */}
        <Box sx={{ display: { xs: "none", sm: "block", md: "none" } }}>
          <IconButton variant="text" onClick={signOutHandler}>
            <ArrowRightOnRectangleIcon width={16} />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Button
            variant="text"
            startIcon={<ArrowRightOnRectangleIcon width={16} />}
            onClick={signOutHandler}
            sx={{ lineHeight: 1, whiteSpace: "nowrap" }}
          >
            Sign Out
          </Button>
        </Box>
        {/* <Box>
            <Button
              variant="text"
              startIcon={<HeartIcon width={16} />}
              to="/for_you"
              component={RouterLink}
            >
              Pentru tine
            </Button>
          </Box>
          <Box>
            <Button
              variant="text"
              startIcon={<CalendarIcon width={16} />}
              to="/events"
              component={RouterLink}
            >
              Evenimente
            </Button>
          </Box>
          <Box>
            <IconButton to="/account" component={RouterLink}>
              <UserIcon width={16} />
            </IconButton>
            <button onClick={() => signOut()}>Sign Out!</button>
          </Box> */}
      </Stack>
    </Box>
  );
};

export default Header;
