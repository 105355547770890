import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import useUserTracking from "utils/useTracking";
import { LogoAZnextGen, PlanetAnimation } from "components/common";

const Error500 = () => {
  const { aspectRatio } = useWindowAspectRatio();

  useUserTracking();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
      }}
    >
      <Box
        sx={{
          width: aspectRatio > 1 ? "auto" : "100%",
          height: aspectRatio > 1 ? "100%" : "auto",
          borderRadius: "50%",
          boxSizing: "border-box",
          aspectRatio: 1,
          boxShadow:
            "0px 0px 200px 0px rgba(89, 28, 168, 0.60), 0px 0px 250px 0px rgba(132, 11, 95, 0.70), 0px 0px 80px 0px rgba(246, 219, 238, 0.15)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            gap: {
              xs: 2,
              sm: 6,
            },
          }}
        >
          <Stack direction="column" alignItems="center">
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "100%",
                },
              }}
            >
              <PlanetAnimation />
            </Box>
            <Stack
              direction="column"
              alignItems="center"
              sx={{ opacity: 0.8 }}
              gap={0.5}
            >
              <Box
                sx={{
                  width: {
                    xs: 240,
                    sm: "auto",
                  },
                  height: {
                    xs: "auto",
                    sm: 66,
                  },
                }}
              >
                <LogoAZnextGen width="100%" height="auto" />
              </Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.5rem",
                    sm: "1.01813rem",
                  },
                  letterSpacing: "-0.02544rem",
                }}
              >
                Website destinat profesioniștilor în domeniul sănătății din
                România.
              </Typography>
            </Stack>
          </Stack>
          <Box textAlign="center">
            <Stack gap={1}>
              <Typography
                color="warning.darker"
                sx={{
                  fontSize: { xs: "2rem", sm: "4rem" },
                  lineHeight: 1,
                  fontWeight: 500,
                  marginBottom: "0 !important",
                }}
              >
                500
              </Typography>
              <Typography sx={{ fontSize: "1.25rem", lineHeight: 1 }}>
                Eroare. Încercați mai târziu.
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Error500;
