import { isAfter } from "date-fns";

const isFutureDate = (date, serverTime) => {
  if (!date) return false;

  const dateToCompare = new Date(date);

  return isAfter(dateToCompare, new Date(serverTime));
};

export default isFutureDate;
