import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";

const LoggedInTemplate = (props) => {
  return (
    <>
      <Header />
      <Box
        sx={{
          paddingTop: "61px",
          paddingBottom: {
            sm: "150px",
            md: "100px",
          },
          height: {
            sm: "100vh",
          },
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

export default LoggedInTemplate;
