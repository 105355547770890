import React from "react";
import { Box } from "@mui/material";

const NiceBox = ({
  colors = {
    boxShadowColor: "rgba(123, 33, 171, 0.35)",
    borderColor: "#A30057",
    borderImage: "linear-gradient(90deg, #A30057 1.22%, #6F1D9A 78.41%)",
  },
  minHeight = "auto",
  onClick = () => {},
  children,
}) => {
  return (
    <Box
      width="100%"
      onClick={onClick}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0.75rem",
        minHeight: minHeight,
        boxShadow: `0px 0px 16px 1px ${colors.boxShadowColor}`,
        overflow: "hidden",
        cursor: "pointer",
        transition: "all 200ms ease-in-out",
        // pointerEvents: "none",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          background: {
            xs: "linear-gradient(180deg, rgb(141, 47, 190, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%)",
            sm: "linear-gradient(180deg, rgba(235, 212, 247, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%)",
          },
          mixBlendMode: "overlay",
          backdropFilter: "blur(4px)",
          transition: "all 200ms ease-in-out",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          borderColor: colors.borderColor,
          borderImage: `${colors.borderImage} 1`,
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderRadius: "0.75rem",
          opacity: 0.5,
          pointerEvents: "none",
          // transition: "all 200ms ease-in-out",
          // mixBlendMode: "color-dodge",
        },
        "&:hover": {
          boxShadow: `0px 0px 200px 24px rgba(97, 25, 141, 0.55)`,
          "&:before": {
            background: `linear-gradient(180deg, rgba(235, 212, 247, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%), ${colors.borderImage}`,
            backgroundBlendMode: "overlay",
            mixBlendMode: "difference",
            // mixBlendMode: "color-dodge",
            transition: "all 200ms ease-in-out",
          },
          "&:after": {
            borderWidth: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default NiceBox;
