import theme from "utils/theme";

export const therapeuticAreasDesign = {
  "cardio-reno-metabolic": {
    image: "/assets/images/therapeutic-areas/cardio-reno-metabolic.png",
    color: "linear-gradient(90deg, #A30057 1.22%, #6F1D9A 78.41%)",
    colorText: "#DA338C",
    hover: {
      borderColor: "#A30057",
      background:
        "linear-gradient(90deg, rgba(163, 0, 101, 0.30) 1.22%, rgba(112, 35, 210, 0.30) 78.41%)",
    },
    boxShadowColor: "rgba(123, 33, 171, 0.35)",
    backgroundColorDetails:
      "linear-gradient(0deg, #250A33 0%, #6F1D9A 20.83%, #B80062 100%)",
  },
  "respirator-imunologie": {
    image: "/assets/images/therapeutic-areas/respirator-imunologie.png",
    color: "linear-gradient(90deg, #208A97 0%, #6F1E9A 100%)",
    colorText: "#86DCE6",
    hover: {
      borderColor: "#208A97",
      background:
        "linear-gradient(90deg, rgba(32, 138, 151, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(36, 135, 152, 0.35)",
    backgroundColorDetails:
      "linear-gradient(180deg, rgba(99, 26, 137, 1) 0%, rgba(53, 111, 153, 1) 78.65%, rgba(42, 88, 121, 1) 99.48%)",
  },
  oncologie: {
    image: "/assets/images/therapeutic-areas/oncologie.png",
    color: "linear-gradient(90deg, #56991E 0%, #6F1E9A 100%)",
    colorText: "#DCE766",
    hover: {
      borderColor: "#56991E",
      background:
        "linear-gradient(90deg, rgba(86, 153, 30, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(131, 143, 0, 0.30)",
    backgroundColorDetails:
      "linear-gradient(0deg, rgba(60, 89, 33, 1) 0%, rgba(86, 153, 30, 1) 17.97%, rgba(111, 30, 154, 1) 100%)",
  },
  "boli-rare": {
    image: "/assets/images/therapeutic-areas/boli-rare.png",
    color: "linear-gradient(90deg, #D67700 0%, #6F1E9A 100%)",
    colorText: "#D67700",
    hover: {
      borderColor: "#D67700",
      background:
        "linear-gradient(90deg, rgba(214, 119, 9, 0.30) 0%, rgba(111, 30, 154, 0.30) 100%)",
    },
    boxShadowColor: "rgba(97, 25, 141, 0.35)",
    backgroundColorDetails: "linear-gradient(0deg, #B86600 0%, #6F1E9A 100%)",
  },
};

export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "0.375rem", // Scrollbar width
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Track color
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.darker, // Thumb color
    borderRadius: "0.25rem", // Rounded corners for thumb
  },
};
