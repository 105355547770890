import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import useWindowAspectRatio from "utils/useWindowAspectRatio";

const CometsAnimation = () => {
  const [numberOfComets, setNumberOfComets] = useState(0);
  const [comets, setComets] = useState([]);
  const { windowWidth, windowHeight } = useWindowAspectRatio();

  const animateComet = (cometPosition) => {
    // Calculate the new position based on the direction
    const speed = cometPosition.scale * 0.5;
    const newX = cometPosition.x + speed;
    const newY = cometPosition.y - speed;
    // console.log(newX, newY);

    // Check if the comet has left the screen
    const commetOffset = cometPosition.scale * 100;
    if (newX > windowWidth + commetOffset || newY < -commetOffset) {
      // Comet has left the screen, remove it
      return null;
    }

    // Update the comet's position
    return { x: newX, y: newY, scale: cometPosition.scale };
  };

  const generateNewComet = (initial = false) => {
    const scaleFactor =
      windowWidth < 600
        ? 1
        : windowWidth < 900
        ? 1.5
        : windowWidth < 1200
        ? 2
        : windowWidth < 1536
        ? 2.5
        : 3;
    const scale = 0.75 + Math.random() * scaleFactor;
    if (initial) {
      return {
        x: Math.random() * windowWidth - 100 * scale,
        y: Math.random() * windowHeight + 100 * scale,
        scale,
      };
    }
    const shortSide =
      -300 + windowWidth <= windowHeight ? windowWidth : windowHeight;
    return {
      x: -shortSide + Math.random() * windowWidth - 100 * scale,
      y: shortSide + Math.random() * windowHeight * 0 + 100 * scale,
      scale,
    };
  };

  useEffect(() => {
    const newComets = [];
    for (let i = 0; i < numberOfComets; i++) {
      newComets.push(generateNewComet(true));
    }
    setComets(newComets);
  }, [numberOfComets]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedComets = comets.map((comet) => {
        const newPosition = animateComet(comet);
        // console.log(newPosition);
        return newPosition ?? generateNewComet();
      });
      setComets(updatedComets);
    }, 10);
    return () => clearInterval(intervalId);
  }, [comets]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setNumberOfComets(10);
  }, []);

  return (
    <div
      style={{ position: "fixed", height: "100vh", width: "100vw", zIndex: -1 }}
    >
      <AnimatePresence>
        {comets.map((comet, i) => (
          <motion.div
            key={i}
            style={{
              position: "absolute",
              backgroundColor: "#fff",
              width: 100,
              height: 100,
              left: comet.x,
              top: comet.y,
              transform: `scale(${comet.scale})`,
              background: "url(/assets/images/comet.svg) no-repeat center",
              backgroundSize: "contain",
              filter: `blur(${0.75 / comet.scale}px)`,
              opacity: comet.scale / 3,
            }}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default CometsAnimation;
