import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  // StepButton,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
// import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { styled } from "@mui/material/styles";

const StepperNext = ({ done = false }) => {
  return done ? null : (
    <Box
      sx={{
        position: "relative",
        width: 8,
        height: 8,
        borderRadius: "50%",
        background: "#fff",
        marginLeft: "20px",
        // opacity: 1,
        "&:before": {
          content: "''",
          position: "absolute",
          left: -20,
          top: 2,
          height: 4,
          width: 20,
          borderRadius: "0.5rem",
          background:
            "linear-gradient(180deg, rgba(235, 212, 247, 0.80) 0%, rgba(235, 212, 247, 0.48) 100%)",
        },
      }}
    />
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: "calc(-50% - 41px)",
    right: "calc(50% + 40px)",
    // left: "-50%",
    // right: "50%",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: "red",
      background:
        "linear-gradient(180deg, rgba(235, 212, 247, 0.80) 0%, rgba(235, 212, 247, 0.48) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: "#A863CF",
      background:
        "linear-gradient(180deg, rgba(235, 212, 247, 0.80) 0%, rgba(235, 212, 247, 0.48) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "transparent",
    // borderTopWidth: 1,
    // borderRadius: 1,
    height: 4,
    borderRadius: "0.5rem",
    // background:
    //   "linear-gradient(180deg, rgba(235, 212, 247, 0.80) 0%, rgba(235, 212, 247, 0.48) 100%)",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active &&
    {
      // opacity: 1,
      // backgroundColor: "#000",
    }),
  "& .QontoStepIcon-completedIcon": {
    color: "#A863CF",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#000",
  },
}));

function QontoStepIcon(props) {
  // const { active, completed, className } = props;
  const { active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {!active ? <StepperNext done /> : <StepperNext />}
    </QontoStepIconRoot>
  );
}

const StepperNonLinear = ({ steps, activeStep, setCurrentStep }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {steps.map((label, index) => (
        <Step
          key={`${label}-${index}`}
          sx={{
            "& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel": {
              textAlign: "left !important",
            },
          }}
        >
          <StepLabel
            StepIconComponent={QontoStepIcon}
            onClick={() => setCurrentStep(index + 1)}
            sx={{
              cursor: "pointer",
              alignItems: "start",
              textTransform: "uppercase",
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperNonLinear;
