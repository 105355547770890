import React, { useContext } from "react";
import { RequireAuth } from "react-auth-kit";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "pages/Login";
import RecoverPassword from "pages/RecoverPassword";
import NewPassword from "pages/NewPassword";
import CheckRef from "pages/CheckRef";
import NewAccountPassword from "pages/NewAccountPassword";
import SignUp from "pages/SignUp";
import Homepage from "pages/Homepage";
import TherapeuticArea from "pages/TherapeuticArea";
import TherapeuticAreaIndication from "pages/TherapeuticAreaIndication";
import Product from "pages/Product";
import Article from "pages/Article";
import Page from "pages/Page";
import Error500 from "pages/Error500";
import CheckHCMID from "components/login/CheckHCMID";
import appContext from "utils/context";

const RoutesComponent = ({ contentRef }) => {
  const { serverTime } = useContext(appContext);

  return (
    <BrowserRouter>
      {serverTime === 0 ? (
        <Routes>
          <Route path="/500" element={<Error500 />} />
          <Route path="*" element={<Navigate to="/500" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login contentRef={contentRef} />} />
          <Route
            path="/recover-password"
            element={<RecoverPassword contentRef={contentRef} />}
          />
          <Route
            path="/email/set-new-password"
            element={<NewPassword contentRef={contentRef} />}
          />
          <Route
            path="/access-request"
            element={<SignUp contentRef={contentRef} />}
          />
          <Route
            path="/new-password/:accessToken"
            element={<NewAccountPassword contentRef={contentRef} />}
          />
          <Route path="/ref/:refToken" element={<CheckRef />} />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug/:therapeuticAreaIndicationSlug/:brandSlug/:contentSlug/:articleSlug/:tabSlug?"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Article contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug/:therapeuticAreaIndicationSlug/:brandSlug/:contentSlug?"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Product contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug/:therapeuticAreaIndicationSlug"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <TherapeuticAreaIndication contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/therapeutic-areas/:therapeuticAreaSlug"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <TherapeuticArea contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/pages/:pageSlug"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Page contentRef={contentRef} />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route
            path="/"
            element={
              <CheckHCMID>
                <RequireAuth loginPath="/login">
                  <Homepage />
                </RequireAuth>
              </CheckHCMID>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default RoutesComponent;
