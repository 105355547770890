import React from "react";

const LogoAZnextGen = ({ width = "auto", height = 16, maxWidth = "auto" }) => {
  return (
    <img
      src="/assets/images/logo-AZnextGen.svg"
      alt="AZNextGen"
      width={width}
      height={height}
      style={{
        verticalAlign: "middle",
        maxWidth,
        // alignSelf: "center",
      }}
    />
  );
};

export default LogoAZnextGen;
