import React, { useEffect } from "react";
import LoginTemplate from "pages/templates/LoginTemplate";
import NewPasswordForm from "components/login/NewPasswordForm";
import { CheckUserAuthenticated } from "components/common";

const NewPassword = ({ contentRef }) => {
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckUserAuthenticated unAuthorizeUser={true}>
      <LoginTemplate paddingTop={7}>
        <NewPasswordForm />
      </LoginTemplate>
    </CheckUserAuthenticated>
  );
};

export default NewPassword;
