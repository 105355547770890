import { therapeuticAreasDesign } from "utils/config-design";

const backgroundOverlays = {
  video: "rgba(14, 9, 20, 0.55)",
  "event-promo": "rgba(2, 2, 2, 0.55)",
  // "event-page": "rgba(2, 2, 2, 0.55)",
  "event-promo-thumbnail": "rgba(0, 0, 0, 0.45)",
  event: "rgba(0, 0, 0, 0.2)",
  article:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%)",
};

export const mediaBackgroundAPI = ({
  isPending = true,
  isError = false,
  item,
  image,
  type = "article",
  fallbackImage = null,
}) => {
  return {
    background:
      isPending || isError
        ? fallbackImage ||
          `${
            therapeuticAreasDesign[
              item.therapeutic_area || "cardio-reno-metabolic"
            ].color
          }, ${backgroundOverlays[type]}`
        : type === "event-page"
        ? `url(data:${image.file_type};base64,${image.content}) no-repeat center`
        : `url(data:${image.file_type};base64,${image.content}) no-repeat center, rgba(14, 9, 20, 0.55)`,
    backgroundBlendMode: type === "event-page" ? "inherit" : "color",
    backgroundSize:
      type === "event-page"
        ? "cover"
        : isPending || isError
        ? "200% 100%"
        : "cover",
    animation: isPending || isError ? "gradient 5s ease infinite" : "none",
    animationPlayState: isError ? "paused" : isPending ? "running" : "unset",
  };
};
