import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import PageInfo from "components/pages/PageInfo";

const Page = ({ contentRef }) => {
  // const user = useContext(Context);
  const { pageSlug } = useParams();

  useEffect(
    () => {
      if (contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSlug]
  );

  return (
    <LoggedInTemplate>
      <PageInfo key={`page-${pageSlug}`} />
    </LoggedInTemplate>
  );
};

export default Page;
