import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Container, Box, Stack, Typography } from "@mui/material";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import theme from "utils/theme";
import { LogoAZnextGen, LinkText, PlanetAnimation } from "components/common";

const SignUpTemplate = (props) => {
  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2, height: "100%" }}>
      <Stack spacing={4} height="100%">
        <Box flexGrow={0}>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Stack direction="column" alignItems="center" gap={2}>
              <Box sx={{ opacity: 0.8 }}>
                <LogoAZnextGen width="100%" height="auto" maxWidth={140} />
              </Box>
              <Box sx={{ width: 120 }}>
                <PlanetAnimation />
              </Box>
            </Stack>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="text"
                startIcon={<ArrowLongLeftIcon width={20} />}
                to="/login"
                component={RouterLink}
              >
                Înapoi la Autentificare
              </Button>
              <Box>
                <LogoAZnextGen />
              </Box>
            </Stack>
          </Box>
          <Box textAlign="center" maxWidth={660} marginX="auto">
            <Typography variant="h1" sx={{ marginBottom: 2 }}>
              Creare cont
            </Typography>
            <Typography
              variant="body2"
              color="primary.darker"
              sx={{ marginBottom: 2 }}
            >
              AZnextGen.ro este un website destinat profesioniștilor în domeniul
              sănătății din România. Pentru a crea un cont vă rugăm să
              completați datele dumneavostră urmând pașii de mai jos.
            </Typography>
            <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
              Aveți deja cont? <LinkText to="/login">Autentificare</LinkText>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexGrow={1}
          sx={{
            position: "relative",
            padding: {
              xs: theme.spacing(4, 0.5, 4, 0.5),
              sm: theme.spacing(4, 4, 8, 4),
              md: theme.spacing(4, 4, 14, 4),
            },
            "&:before": {
              xs: "",
              sm: {
                content: "''",
                position: "absolute",
                zIndex: -1,
                borderRadius: "5rem 5rem 0rem 0rem",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(316deg, rgba(128, 71, 215, 0.15) 29.19%, rgba(136, 48, 156, 0.15) 73.82%, rgba(124, 54, 161, 0.15) 78.95%)",
                backdropFilter: "blur(16px)",
              },
            },
          }}
        >
          {props.children}
        </Box>
      </Stack>
    </Container>
  );
};

export default SignUpTemplate;
