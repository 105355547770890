import React from "react";
import { Grid, Container, Box, Typography, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import theme from "utils/theme";
import { LogoAZnextGen, PlanetAnimation } from "components/common";

const LoginTemplate = ({ paddingTop = 6, children }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: {
          xs: theme.spacing(7, 3, 3, 3),
          sm: theme.spacing(7, 4, 4, 4),
          md: theme.spacing(7, 5, 14, 5),
          lg: theme.spacing(7, 0, 14, 0),
        },
        height: {
          sm: "100vh",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <RouterLink to="/login">
            <img
              src="/assets/images/logo-AstraZeneca.svg"
              alt="AstraZeneca"
              height={24}
            />
          </RouterLink>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block", md: "none" } }}>
          <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Box sx={{ opacity: 0.8 }}>
              <LogoAZnextGen width="100%" height="auto" maxWidth={210} />
              <Typography
                sx={{
                  fontSize: "0.42rem",
                  paddingTop: 0.5,
                  lineHeight: 1.3,
                  letterSpacing: "-0.01025rem",
                }}
              >
                Website destinat profesioniștilor în domeniul sănătății din
                România.
              </Typography>
            </Box>
            <Box sx={{ width: 250 }}>
              <PlanetAnimation />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Stack direction="column" alignItems="center" gap={2}>
            <Box sx={{ opacity: 0.8 }}>
              <LogoAZnextGen width="100%" height="auto" maxWidth={140} />
            </Box>
            <Box sx={{ width: 120 }}>
              <PlanetAnimation />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Grid
        flexGrow={1}
        container
        columnSpacing={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={7} lg={6}>
          <Box
            sx={{
              padding: theme.spacing(paddingTop, 0, 18, 0),
              maxWidth: {
                xs: "100%",
                sm: 400,
                md: 450,
              },
              marginX: {
                xs: "auto",
                md: 0,
              },
            }}
          >
            {children}
          </Box>
        </Grid>
        <Grid
          item
          md={5}
          lg={6}
          sx={{
            alignSelf: "center",
            textAlign: "center",
            display: { xs: "none", md: "block" },
          }}
        >
          <PlanetAnimation />
          <Box sx={{ opacity: 0.8 }}>
            <LogoAZnextGen width="100%" height="auto" maxWidth={514} />
            <Typography
              sx={{
                paddingTop: 0.5,
                fontSize: {
                  md: ".75rem",
                  lg: "1.01813rem",
                },
                letterSpacing: "-0.02544rem",
              }}
            >
              Website destinat profesioniștilor în domeniul sănătății din
              România.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginTemplate;
