import React, { useState, useEffect } from "react";
import {
  useNavigate,
  // Link as RouterLink
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "utils/theme";
import { Icon } from "components/common";
import { GetContentTherapeuticAreas } from "utils/api/getContent";
import useWindowAspectRatio from "utils/useWindowAspectRatio";

const TherapeuticAreaHover = ({ therapeuticArea }) => {
  const commonStyles = {
    position: "absolute",
    width: 580,
    height: 430,
    top: 60,
    left: -100,
    boxSizing: "border-box",
    borderRadius: "1.5rem",
  };

  return (
    <>
      <motion.div
        easing="easeIn"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        style={{
          zIndex: 1,
          background: `linear-gradient(180deg, rgba(235, 212, 247, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%), ${therapeuticArea.hover.background}`,
          // background: `linear-gradient(180deg, rgba(235, 212, 247, 1) 0%, rgba(235, 212, 247, 1) 100%), ${therapeuticArea.hover.background}`,
          backgroundBlendMode: "overlay",
          mixBlendMode: "hue",
          ...commonStyles,
        }}
      />
      <motion.div
        easing="easeIn"
        initial={{ boxShadow: "0px 0px 10px 1px rgba(97, 25, 141, 0)" }}
        animate={{ boxShadow: "0px 0px 200px 24px rgba(97, 25, 141, 0.55)" }}
        exit={{ boxShadow: "0px 0px 10px 1px rgba(97, 25, 141, 0)" }}
        transition={{ duration: 0.2 }}
        style={{
          zIndex: 2,
          overflow: "hidden",
          backdropFilter: "blur(6px)",
          mixBlendMode: "overlay",
          ...commonStyles,
        }}
      />
      <motion.div
        easing="easeIn"
        initial={{ opacity: 0, borderWidth: 4, scale: 1.05 }}
        animate={{ opacity: 1, borderWidth: 1, scale: 1 }}
        exit={{ opacity: 0, borderWidth: 1, scale: 1.05 }}
        transition={{ duration: 0.2 }}
        style={{
          zIndex: 3,
          border: "1px solid",
          borderColor: therapeuticArea.hover.borderColor,
          mixBlendMode: "difference",
          ...commonStyles,
        }}
      />
    </>
  );
};

const TherapeuticAreaHoverButton = () => {
  return (
    <motion.div
      easing="easeIn"
      initial={{ opacity: 0, top: "55%" }}
      animate={{ opacity: 1, top: "50%" }}
      exit={{ opacity: 0, top: "28%" }}
      transition={{ duration: 0.5 }}
      style={{
        position: "absolute",
        zIndex: 3,
        left: "50%",
        transform: "translate(-50%, -20px)",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        columnGap: theme.spacing(1.25),
        padding: theme.spacing(0.5, 0.75, 0.5, 1.5),
        borderRadius: "1.5rem",
        background:
          "linear-gradient(180deg, rgba(235, 212, 247, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%)",
        boxShadow: "0px 0px 200px 24px rgba(97, 25, 141, 0.55)",
        backdropFilter: "blur(6px)",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          marginBottom: "0 !important",
          letterSpacing: "0.05rem",
          lineHeight: 1,
        }}
        component="div"
      >
        Accesează
      </Typography>
      <Box>
        <Icon name="arrow-narrow-right-circle" height={20} marginTop={0} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 4,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          borderRadius: "1.5rem",
          border: "0.5px solid rgba(255, 255, 255, 0.25)",
          mixBlendMode: "overlay",
        }}
      />
    </motion.div>
  );
};

const Animation = () => {
  const navigate = useNavigate();
  const { data: therapeuticAreasData, isError } = GetContentTherapeuticAreas();

  const [therapeuticAreas, setTherapeuticAreas] = useState(null);

  const [animationStopAreaIndex, setAnimationStopAreaIndex] = useState(null);
  const [animationHoverAreaIndex, setAnimationHoverAreaIndex] = useState(null);
  const [animationHoverAreaIndexTemp, setAnimationHoverAreaIndexTemp] =
    useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const updateTherapeuticAreaPosition = (
    initialSetup = false,
    therapeuticAreasTemp = therapeuticAreas
  ) => {
    if (
      isAnimating ||
      initialSetup
      // && animationStopAreaIndex !== -1 && animationStopAreaIndex !== null
    ) {
      let stopAnimation = false;
      let therapeuticAreasNew = [...therapeuticAreasTemp];
      let speed = 1;

      if (animationStopAreaIndex !== null) {
        const distance = Math.abs(
          Math.abs(therapeuticAreasNew[animationStopAreaIndex].angle) - 270
        );
        speed = Math.abs(Math.floor(distance)) / 5;
        // console.log("speed", speed, "distance", distance, "angle", therapeuticAreasNew[animationStopAreaIndex].angle);
      }

      therapeuticAreasNew.forEach((therapeuticArea, index) => {
        therapeuticArea.angle -= initialSetup
          ? 0
          : 0.25 * (animationStopAreaIndex !== null ? speed : 1);
        therapeuticArea.angle %= 360;
        if (animationStopAreaIndex === index) {
          // console.log(index, Math.abs(therapeuticArea.angle));
          if (Math.abs(Math.abs(therapeuticArea.angle) - 270) <= 1) {
            stopAnimation = true;
          }
        }
        const calcAngle = (2 * Math.PI * therapeuticArea.angle) / 360;
        therapeuticArea.x = 500 * Math.cos(calcAngle);
        therapeuticArea.y = 150 * Math.sin(calcAngle);
        therapeuticArea.scale = 1 + therapeuticArea.y / 500;
        therapeuticArea.zIndex = Math.floor(4 * Math.sin(calcAngle) + 4);
      });

      if (stopAnimation) {
        therapeuticAreasNew.forEach((therapeuticArea, index) => {
          // sets the final angles/positions
          if (index === animationStopAreaIndex % 4) {
            therapeuticArea.angle = -270;
          }
          if (index === (animationStopAreaIndex + 1) % 4) {
            therapeuticArea.angle = -180;
          }
          if (index === (animationStopAreaIndex + 2) % 4) {
            therapeuticArea.angle = -90;
          }
          if (index === (animationStopAreaIndex + 3) % 4) {
            therapeuticArea.angle = 0;
          }

          const calcAngle = (2 * Math.PI * therapeuticArea.angle) / 360;
          therapeuticArea.x = 500 * Math.cos(calcAngle);
          therapeuticArea.y = 150 * Math.sin(calcAngle);
          therapeuticArea.scale = 1 + therapeuticArea.y / 500;
          therapeuticArea.zIndex = Math.floor(4 * Math.sin(calcAngle) + 4);
        });
      }

      setTherapeuticAreas([...therapeuticAreasNew]);

      if (stopAnimation) {
        setIsAnimating(false);
        setAnimationStopAreaIndex(-1);
      }
    }
  };

  // First time wait for 3 seconds and then start animation
  useEffect(() => {
    // console.log("therapeuticAreasData", therapeuticAreasData);
    if (therapeuticAreasData && !isError) {
      const therapeuticAreasTemp = Object.keys(therapeuticAreasData).map(
        (item, index) => ({
          ...therapeuticAreasData[item],
          slug: item,
          angle: (index - 3) * 90,
          x: 0,
          y: 0,
          scale: 1,
        })
      );

      updateTherapeuticAreaPosition(true, therapeuticAreasTemp);

      const timeout = setTimeout(() => {
        if (animationStopAreaIndex === null) {
          setIsAnimating(true);
        }
        // setAnimationStopAreaIndex(null);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [therapeuticAreasData, isError]); // eslint-disable-line react-hooks/exhaustive-deps

  // Animation speed interval
  useEffect(() => {
    if (therapeuticAreasData && !isError) {
      if (isAnimating) {
        const interval = setInterval(updateTherapeuticAreaPosition, 15);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [isAnimating, animationStopAreaIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wait for 5 seconds for the user to choose the Therapeutic Area
  const [waitingForTherapeuticAreaClick, setWaitingForTherapeuticAreaClick] =
    useState(false);
  useEffect(() => {
    if (therapeuticAreasData && !isError) {
      const clearAnimation = () => {
        if (animationStopAreaIndex === -1) {
          if (animationHoverAreaIndex === animationHoverAreaIndexTemp) {
            setIsAnimating(true);
            setAnimationStopAreaIndex(null);
            setAnimationHoverAreaIndex(null);
          } else {
            setAnimationStopAreaIndex(null);
            setAnimationHoverAreaIndex(animationHoverAreaIndexTemp);
          }
          setWaitingForTherapeuticAreaClick(false);
        }
      };

      if (animationStopAreaIndex === -1) {
        setWaitingForTherapeuticAreaClick(true);
        const timeout = setTimeout(clearAnimation, 5000);

        return () => {
          clearTimeout(timeout);
          setWaitingForTherapeuticAreaClick(false);
        };
      }
    }
  }, [animationStopAreaIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const { windowWidth } = useWindowAspectRatio();

  const animationScale = Math.min(windowWidth / 1460, 1);
  const isVerticalOrientation = useMediaQuery("(orientation: portrait)");

  return (
    therapeuticAreas && (
      <Box
        sx={{
          position: "relative",
          width: 380,
          height: 500,
          margin: "auto",
          marginTop: {
            xs: 20,
            md: isVerticalOrientation ? 15 : 12,
            lg: 20,
          },
          marginBottom: 10,
          transform: windowWidth < 1280 ? `scale(${animationScale})` : "none",
          transformOrigin: "top center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 0,
            width: 610,
            height: 400,
            borderRadius: "50%",
            background: "rgba(132, 11, 95, 0.30)",
            filter: "blur(100px)",
            transform: "translate(-100px, 80px)",
          }}
        />
        <AnimatePresence>
          {animationHoverAreaIndex !== null &&
            therapeuticAreas[animationHoverAreaIndex].angle === -270 && (
              <TherapeuticAreaHover
                therapeuticArea={therapeuticAreas[animationHoverAreaIndex]}
              />
            )}
        </AnimatePresence>
        {therapeuticAreas.map((therapeuticArea, index) => (
          <motion.div
            easing="easeIn"
            key={index}
            style={{
              position: "absolute",
              left: therapeuticArea.x + "px",
              top: therapeuticArea.y + "px",
              transform: `scale(${therapeuticArea.scale})`,
              zIndex: therapeuticArea.zIndex,
            }}
          >
            <Box
              textAlign="center"
              sx={{ width: 380, cursor: "pointer" }}
              onMouseEnter={() => {
                if (animationStopAreaIndex === null) {
                  setIsAnimating(false);
                  setAnimationHoverAreaIndex(index);
                }
                setAnimationHoverAreaIndexTemp(index);
              }}
              onMouseLeave={() => {
                if (
                  (animationStopAreaIndex === null ||
                    animationStopAreaIndex === -1) &&
                  !waitingForTherapeuticAreaClick
                ) {
                  setIsAnimating(true);
                  setAnimationStopAreaIndex(null);
                  setAnimationHoverAreaIndex(null);
                }
                setAnimationHoverAreaIndexTemp(null);
              }}
              onClick={() => {
                if (windowWidth <= 1280) {
                  navigate(
                    `/therapeutic-areas/${therapeuticAreas[index].slug}`
                  );
                } else if (
                  animationHoverAreaIndex === index &&
                  therapeuticArea.angle === -270
                ) {
                  navigate(
                    `/therapeutic-areas/${therapeuticAreas[animationHoverAreaIndex].slug}`
                  );
                } else {
                  setAnimationStopAreaIndex(index);
                  setAnimationHoverAreaIndex(index);
                  setIsAnimating(true);
                }
              }}
            >
              <img
                src={therapeuticArea.image}
                height={220}
                alt={therapeuticArea.title}
              />
              <Typography
                variant="h1"
                style={{
                  // marginTop: theme.spacing(1),
                  background: therapeuticArea.color,
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  lineHeight: 1.5,
                }}
                component="span"
              >
                {therapeuticArea.title}
                {/* <Typography variant="body2">{therapeuticArea.angle}</Typography> */}
              </Typography>
              <AnimatePresence>
                {therapeuticArea.angle === -270 &&
                  animationHoverAreaIndex === index && (
                    <TherapeuticAreaHoverButton />
                  )}
              </AnimatePresence>
            </Box>
          </motion.div>
        ))}
      </Box>
    )
  );
};

export default Animation;
