import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import ArticleInfo from "components/articles/ArticleInfo";
import EventInfo from "components/events/EventInfo";

const Article = ({ contentRef }) => {
  // const user = useContext(Context);
  const {
    therapeuticAreaSlug,
    therapeuticAreaIndicationSlug,
    brandSlug,
    contentSlug,
    articleSlug,
  } = useParams();

  useEffect(
    () => {
      if (contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      therapeuticAreaSlug,
      therapeuticAreaIndicationSlug,
      brandSlug,
      contentSlug,
      articleSlug,
    ]
  );

  return (
    <LoggedInTemplate>
      {contentSlug !== "events" ? (
        <ArticleInfo
          key={`${therapeuticAreaSlug}-${therapeuticAreaIndicationSlug}-${brandSlug}-${contentSlug}-${articleSlug}`}
        />
      ) : (
        <EventInfo
          key={`${therapeuticAreaSlug}-${therapeuticAreaIndicationSlug}-${brandSlug}-${contentSlug}-${articleSlug}`}
        />
      )}
    </LoggedInTemplate>
  );
};

export default Article;
