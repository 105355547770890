import React from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import {
  useNavigate,
  // Link as RouterLink
} from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { ContentBoxHover } from "components/common";
import theme from "utils/theme";
import { GetMedia } from "utils/api/getContent";
import { mediaBackgroundAPI } from "utils/media";

const ArticleBox = ({
  article,
  isWhite = false,
  isHorizontal = false,
  isSmall = false,
  isSquareImage = false,
  isDetailed = false,
}) => {
  const navigate = useNavigate();
  const articleURL = `/therapeutic-areas/${article.therapeutic_area}/${article.therapeutic_area_indication}/${article.brand_slug}/articles/${article.slug}`;

  const { data: articleImage, isError, isPending } = GetMedia(article.image_id); // "5b0c7126-8ec2-11ee-ac3d-4e619492ee6c"

  return (
    <ContentBoxHover
      isWhite={isWhite}
      borderRadius="0.75rem"
      onClick={() => navigate(articleURL)}
    >
      <Stack
        direction={!isHorizontal ? "column" : "row"}
        justifyContent="stretch"
        height="100%"
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: isHorizontal && !isSmall ? 0 : "0.75rem",
            overflow: "hidden",
            minWidth: isSmall ? 104 : isHorizontal ? 214 : "100%",
            // minHeight: isSmall ? 71 : isHorizontal ? 130 : 200,
            aspectRatio: isSquareImage ? 1 : "16/9",
            marginBottom: isHorizontal ? 0 : 1,
            ...mediaBackgroundAPI({
              type: "article",
              item: article,
              image: articleImage,
              isPending,
              isError,
            }),
          }}
        >
          {isSmall && article.duration && (
            <Box
              sx={{
                position: "absolute",
                left: theme.spacing(1),
                bottom: theme.spacing(1),
                borderRadius: "0.125rem",
                background: "rgba(12, 12, 12, 0.40)",
                paddingX: 0.5,
              }}
            >
              <Stack direction="row" alignItems="center" gap={0.25}>
                <ClockIcon
                  width={12}
                  style={{
                    color: isDetailed
                      ? "secondary.dark"
                      : isWhite
                      ? "primary.black"
                      : "secondary.lighter",
                    opacity: 0.8,
                  }}
                />
                <Typography
                  variant="body4"
                  color={
                    isDetailed
                      ? "secondary.dark"
                      : isWhite
                      ? "primary.black"
                      : "primary"
                  }
                >
                  {article.duration}
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
        <Stack
          direction="column"
          padding={isHorizontal || isDetailed ? theme.spacing(1, 2) : 1}
          flexGrow={1}
          gap={isSmall ? 0.5 : 1}
          justifyContent={isHorizontal ? "center" : "space-between"}
        >
          <Typography
            className="item-title"
            variant={isSmall ? "body3" : !isWhite ? "body1" : "body2"}
            sx={{
              ...(isHorizontal
                ? {
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }
                : {}),
              ...(isDetailed
                ? {
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }
                : {}),
              fontWeight: !isWhite ? "inherit" : 500,
              color: !isWhite ? "inherit" : "#000",
              lineHeight: isSmall ? 1.5 : !isWhite ? "inherit" : "1.5rem",
            }}
          >
            {article.title}
          </Typography>
          {isDetailed && (
            <Tooltip
              title={
                article.description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.description || "",
                    }}
                  ></div>
                ) : (
                  ""
                )
              }
            >
              <Typography
                variant="body3"
                component="p"
                sx={{
                  fontWeight: 300,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: "vertical",
                }}
                dangerouslySetInnerHTML={{
                  __html: article.description || "",
                }}
              ></Typography>
            </Tooltip>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {!isSmall && article.duration && (
              <Stack direction="row" alignItems="center" gap={0.25}>
                <ClockIcon
                  width={16}
                  style={{
                    color: isDetailed
                      ? "secondary.dark"
                      : isWhite
                      ? "primary.black"
                      : "secondary.lighter",
                    opacity: 0.8,
                  }}
                />
                <Typography
                  variant={!isWhite ? "body2" : "body3"}
                  color={
                    isDetailed
                      ? "secondary.dark"
                      : isWhite
                      ? "primary.black"
                      : "primary"
                  }
                >
                  {article.duration}
                </Typography>
              </Stack>
            )}
            {isDetailed && (
              <Box>
                <Typography variant="body2" color="secondary.dark">
                  {format(new Date(article.publish_date), "dd.MM.yyyy")}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography
                variant="body3"
                color={
                  isDetailed
                    ? "primary.gray3"
                    : isWhite
                    ? "primary.gray2"
                    : "primary.gray"
                }
              >
                {article.approval_code}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </ContentBoxHover>
  );
};

export default ArticleBox;
