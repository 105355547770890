import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import TherapeuticAreaIndicationInfo from "components/therapeutic-areas/TherapeuticAreaIndicationInfo";

const TherapeuticArea = ({ contentRef }) => {
  // const user = useContext(Context);
  const { therapeuticAreaSlug, therapeuticAreaIndicationSlug } = useParams();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [therapeuticAreaSlug, therapeuticAreaIndicationSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoggedInTemplate>
      <TherapeuticAreaIndicationInfo
        key={`${therapeuticAreaSlug}-${therapeuticAreaIndicationSlug}`}
      />
    </LoggedInTemplate>
  );
};

export default TherapeuticArea;
