import React from "react";
import { Typography, Box } from "@mui/material";
// import { Icon } from "components/common";
import { useAuthUser } from "react-auth-kit";
// import { Context } from "context";
import ChooseTherapeuticAreasAnimation from "components/therapeutic-areas/ChooseTherapeuticAreasAnimation";
import ChooseTherapeuticAreasAnimationMobile from "components/therapeutic-areas/ChooseTherapeuticAreasAnimationMobile";

const ChooseTherapeuticAreas = () => {
  // const user = useContext(Context);
  const auth = useAuthUser();

  return (
    <>
      <Box textAlign="center" marginTop={6}>
        <Typography variant="h1">
          Bine ai venit, {auth().first_name} {auth().last_name}!
        </Typography>
        <Typography>Explorează ariile terapeutice AstraZeneca</Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <ChooseTherapeuticAreasAnimationMobile />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          paddingBottom: 10,
        }}
      >
        <ChooseTherapeuticAreasAnimation />
      </Box>
    </>
  );
};

export default ChooseTherapeuticAreas;
