import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress } from "@mui/material";
import config from "utils/config";
import axios from "axios";

const CheckRef = () => {
  const { refToken } = useParams();
  const refFull = `${refToken}${window.location.search}`;

  const navigate = useNavigate();

  useEffect(() => {
    const checkRef = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/ref/${refFull}`);

        if (response.data.redirect_link_url) {
          window.open(response.data.redirect_link_url, "_self");
        }
      } catch (err) {
        navigate("/login");
      }
    };
    checkRef();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      maxWidth="xl"
      sx={{
        position: "relative",
        paddingTop: 3,
        paddingBottom: {
          xs: "80px",
          sm: "170px",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100%",
        flexGrow: 1,
      }}
    >
      <Box textAlign="center">
        <CircularProgress size={32} />
      </Box>
    </Container>
  );
};

export default CheckRef;
