import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import ProductInfo from "components/therapeutic-areas/ProductInfo";

const Product = ({ contentRef }) => {
  // const user = useContext(Context);
  const { therapeuticAreaSlug, therapeuticAreaIndicationSlug, brandSlug } =
    useParams();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [therapeuticAreaSlug, therapeuticAreaIndicationSlug, brandSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoggedInTemplate>
      <ProductInfo
        key={`${therapeuticAreaSlug}-${therapeuticAreaIndicationSlug}-${brandSlug}`}
      />
    </LoggedInTemplate>
  );
};

export default Product;
