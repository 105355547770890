import React from "react";
import { Box } from "@mui/material";

const PlanetAnimation = ({ showQuestion = false }) => {
  return (
    <Box sx={{ position: "relative", marginBottom: 2 }}>
      <img
        src="/assets/images/planet/planet-animation-orb-planet-lines-overlay.svg"
        alt="Linii planeta"
        style={{ mixBlendMode: "overlay" }}
      />
      <Box
        sx={{
          "& img": {
            position: "absolute",
            width: "100%",
            left: 0,
            top: 0,
            zIndex: 1,
          },
        }}
      >
        <img
          src="/assets/images/planet/planet-animation-orb-planet-lines-overlay.svg"
          alt="Linii planeta"
          style={{ mixBlendMode: "overlay" }}
        />
        <img
          src="/assets/images/planet/planet-animation-orb-planet-lines-overlay.svg"
          alt="Linii planeta"
          style={{ mixBlendMode: "overlay" }}
        />
        <img
          src="/assets/images/planet/planet-animation-orb-glow-screen.svg"
          alt="Stralucire planeta"
          style={{ mixBlendMode: "screen" }}
        />
        <img
          src="/assets/images/planet/planet-animation-light-glow-screen.svg"
          alt="Stralucire planeta"
          style={{ mixBlendMode: "screen" }}
        />
        <img
          src="/assets/images/planet/planet-animation-light-darken.svg"
          alt="Gradient planeta"
          style={{ mixBlendMode: "darken" }}
        />
        {showQuestion ? (
          <img
            src="/assets/images/planet/planet-animation-icon-question.svg"
            alt="AstraZeneca icon"
          />
        ) : (
          <img
            src="/assets/images/planet/planet-animation-icon.svg"
            alt="AstraZeneca icon"
          />
        )}

        <img
          src="/assets/images/planet/planet-animation-rings.svg"
          alt="Inele planeta"
          style={{ zIndex: 2 }}
        />
        <img
          src="/assets/images/planet/planet-animation-satellite.svg"
          alt="Satelit planeta"
          style={{ zIndex: 2 }}
        />
      </Box>
    </Box>
  );
};

export default PlanetAnimation;
