import React, { useContext } from "react";
import { Box, Stack, Typography, Button, Grid } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  // ChevronDownIcon,
  ClockIcon,
  MicrophoneIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
// import { PlayIcon } from "@heroicons/react/24/solid";
import { Icon, ContentBoxHover } from "components/common";
import { GetMedia } from "utils/api/getContent";
import { mediaBackgroundAPI } from "utils/media";
import theme from "utils/theme";
import { format } from "date-fns";
import ro from "date-fns/locale/ro";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import isFutureDate from "utils/isFutureDate";
import appContext from "utils/context";

const EventDate = ({ event, width = "auto" }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        width,
        minWidth: 120,
        borderRadius: "0.5rem",
        overflow: "hidden",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(180deg, rgba(235, 212, 247, 0.32) 0%, rgba(235, 212, 247, 0.08) 100%)",
          // background-blend-mode: overlay;
          mixBlendMode: "overlay",
          backdropFilter: "blur(2px)",
          transition: "all 200ms ease-in-out",
        },
        "&:after": {
          content: "''",
          top: 0,
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          border: "0.5px solid rgba(255, 255, 255, 0.5)",
          borderRadius: "0.5rem",
          mixBlendMode: "overlay",
          pointerEvents: "none",
          // opacity: 0.5,
          // transition: "all 200ms ease-in-out",
          // mixBlendMode: "color-dodge",
        },
      }}
    >
      <Stack
        divider={
          <Box
            sx={{
              width: "100%",
              height: 0,
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.5)",
              // transform: "scaleY(0.5)",
              mixBlendMode: "overlay",
            }}
          />
        }
        sx={{ position: "relative" }}
      >
        <Box textAlign="center" padding={theme.spacing(0, 1)}>
          <Typography
            variant="body3"
            component="span"
            color="primary"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {format(new Date(event.date), "dd MMM yyyy", {
              locale: ro,
            })}
          </Typography>
        </Box>
        <Box padding={1}>
          <Grid container>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                gap={0.25}
                sx={{ margin: "auto" }}
              >
                <ClockIcon
                  width={14}
                  style={{
                    color: theme.palette.primary.main,
                    opacity: 0.6,
                  }}
                />
                <Typography variant="body4" color="primary">
                  {format(new Date(event.date), "HH:mm")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                gap={0.25}
                sx={{ margin: "auto" }}
              >
                <MicrophoneIcon
                  width={14}
                  style={{
                    color: theme.palette.primary.main,
                    opacity: 0.6,
                  }}
                />
                <Typography
                  variant="body4"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  {event.type}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

const EventDateHorizontal = ({ event }) => {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack direction="row" alignItems="center" gap={0.5}>
        <CalendarIcon
          width={14}
          style={{
            color: theme.palette.primary.main,
            opacity: 0.6,
            marginTop: -1,
          }}
        />
        <Typography variant="body4" color="primary">
          {format(new Date(event.date), "dd MMMM yyyy", {
            locale: ro,
          })}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <ClockIcon
          width={14}
          style={{
            color: theme.palette.primary.main,
            opacity: 0.6,
            marginTop: -1,
          }}
        />
        <Typography variant="body4" color="primary">
          {format(new Date(event.date), "HH:mm")}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <MicrophoneIcon
          width={14}
          style={{
            color: theme.palette.primary.main,
            opacity: 0.6,
            marginTop: -1,
          }}
        />
        <Typography
          variant="body4"
          color="primary"
          sx={{ textTransform: "capitalize" }}
        >
          {event.type}
        </Typography>
      </Stack>
    </Stack>
  );
};

const EventBox = ({
  event,
  isPromo = false,
  isWhite = false,
  isHorizontal = false,
  isSmall = false,
}) => {
  const navigate = useNavigate();
  const eventURL = `/therapeutic-areas/${event.therapeutic_area}/${event.therapeutic_area_indication}/${event.brand_slug}/events/${event.slug}`;

  const { data: eventImage, isError, isPending } = GetMedia(event.image_id); // "5b0c7126-8ec2-11ee-ac3d-4e619492ee6c"

  const { windowWidth } = useWindowAspectRatio();

  const { serverTime } = useContext(appContext);

  const EventImage = () => {
    return (
      <Box
        onClick={() => navigate(eventURL)}
        sx={{
          cursor: "pointer",
          position: "relative",
          borderRadius: "0.75rem",
          border: "1px solid rgba(255, 255, 255, 0.10)",
          overflow: "hidden",
          ...mediaBackgroundAPI({
            type: "event-promo-thumbnail",
            item: event,
            image: eventImage,
            isPending,
            isError,
          }),
          aspectRatio: "16/9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 200ms ease-in-out",
          "&:hover": {
            boxShadow: "0px 0px 30px 1px rgba(255, 255, 255, 0.25)",
          },
        }}
      />
    );
  };

  return isPromo ? (
    <ContentBoxHover cursor="auto" isWhite>
      <Box
        sx={{
          borderRadius: "0.75rem",
          overflow: "hidden",
          background:
            "url(/assets/images/background-event.jpg) no-repeat center, rgba(14, 9, 20, 0.55)",
          backgroundBlendMode: "color",
          backgroundSize: "cover",
        }}
      >
        <Box padding={3}>
          <Box marginBottom={3}>
            <Typography
              variant="body2"
              color="primary"
              component="p"
              sx={{ lineHeight: 1, fontWeight: 400 }}
            >
              URMĂTORUL EVENIMENT
            </Typography>
          </Box>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={3}
            alignItems="start"
          >
            <Box display={{ xs: "block", sm: "none" }} width="100%">
              <EventImage />
            </Box>
            <Box>
              <EventDate
                event={event}
                width={windowWidth >= 1280 ? "auto" : 160}
              />
              <Box display={{ xs: "none", sm: "block", lg: "none" }}>
                <EventImage />
              </Box>
            </Box>
            <Box flexGrow={1}>
              <Box marginBottom={1}>
                <Typography
                  component={RouterLink}
                  to={eventURL}
                  variant="h2"
                  color="primary"
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: 300,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {event.title}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                component="p"
                color="primary.dark"
                // style={{ paddingBottom: "0.5rem" }}
              >
                {event.description}
              </Typography>
              <Typography variant="body3" component="p" color="primary.darker">
                {event.approval_code}
              </Typography>
            </Box>
            <Box width={278} display={{ xs: "none", lg: "block" }}>
              <EventImage />
            </Box>
            <Box
              alignSelf="center"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => navigate(eventURL)}
                variant="contained"
                sx={{
                  padding: theme.spacing(1, 2),
                  fontSize: "1rem",
                }}
              >
                PARTICIP
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </ContentBoxHover>
  ) : (
    <ContentBoxHover
      isWhite={isWhite}
      borderRadius="0.75rem"
      onClick={() => navigate(eventURL)}
    >
      <Stack direction={isHorizontal ? "row" : "column"} alignItems="stretch">
        {!isSmall ? (
          <Box
            sx={{
              borderRadius: "0.75rem",
              overflow: "hidden",
              // height: 200,
              aspectRatio: "16/9",
              marginBottom: 1,
              ...mediaBackgroundAPI({
                type: "event",
                item: event,
                image: eventImage,
                isPending,
                isError,
              }),
            }}
          >
            <Box padding={2}>
              <EventDate event={event} />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0.75rem",
              border: "0.5px solid rgba(248, 248, 248, 0.125)",
              padding: 1.5,
            }}
          >
            <Box width={56}>
              <Icon
                name={`/assets/images/products/${
                  !isWhite ? "white" : "color"
                }/${event.brand_slug}`}
                fullPath={true}
                width="100%"
                height="auto"
                marginTop={0}
              />
            </Box>
          </Box>
        )}
        <Box padding={isWhite || isSmall ? theme.spacing(1, 2, 2, 2) : 1}>
          <Stack direction="row" gap={2} marginBottom={1}>
            {!isSmall && (
              <Box width={80} paddingX={1}>
                <Icon
                  name={`/assets/images/products/${
                    !isWhite ? "white" : "color"
                  }/${event.brand_slug}`}
                  fullPath={true}
                  width="100%"
                  height="auto"
                  marginTop={0}
                />
              </Box>
            )}
            <Stack direction="column" gap={isSmall ? 0 : 1} width="100%">
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                width="100%"
                justifyContent="space-between"
                marginBottom={isSmall ? 0 : 1}
              >
                <Typography
                  variant={!isSmall ? "h3" : "body"}
                  className="item-title"
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    marginBottom: 0,
                    fontWeight: isWhite ? 500 : "inherit",
                  }}
                >
                  {event.title}
                </Typography>
                {!isSmall && isFutureDate(event.date, serverTime) && (
                  <Box display={{ xs: "none", sm: "block" }}>
                    <Button
                      variant={!isWhite ? "outlined" : "contained"}
                      // endIcon={
                      //   <ChevronDownIcon
                      //     width={20}
                      //     style={{ color: "primary" }}
                      //   />
                      // }
                      sx={{
                        flexShrink: 0,
                        padding: theme.spacing(1, 2),
                        fontSize: "1rem",
                        boxShadow: !isWhite ? "inherit" : "none",
                      }}
                    >
                      Particip
                    </Button>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Stack>
          {isFutureDate(event.date, serverTime) && (
            <Box display={{ xs: "block", sm: "none" }} marginBottom={2}>
              <Button
                variant={!isWhite ? "outlined" : "contained"}
                // endIcon={
                //   <ChevronDownIcon
                //     width={20}
                //     style={{ color: "primary" }}
                //   />
                // }
                sx={{
                  flexShrink: 0,
                  padding: theme.spacing(1, 2),
                  fontSize: "1rem",
                  boxShadow: !isWhite ? "inherit" : "none",
                }}
              >
                Particip
              </Button>
            </Box>
          )}
          {!isSmall ? (
            <Stack direction="row" gap={2}>
              {!isWhite && <Box width={80} paddingX={1}></Box>}
              <Stack direction="column" gap={1} width="100%">
                <Typography
                  component="p"
                  variant={isSmall ? "body4" : !isWhite ? "body2" : "body3"}
                  color={!isWhite ? "secondary" : "secondary.darkest"}
                  sx={{
                    fontWeight: isWhite ? 300 : "inherit",
                  }}
                >
                  {event.description}
                </Typography>
                <Typography
                  component="p"
                  variant={isSmall ? "body3" : !isWhite ? "body2" : "body4"}
                  color={!isWhite ? "primary.gray" : "primary.gray3"}
                >
                  {event.approval_code}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <EventDateHorizontal event={event} />
          )}
        </Box>
      </Stack>
    </ContentBoxHover>
  );
};

export default EventBox;
