import React, { useEffect } from "react";
import LoginTemplate from "pages/templates/LoginTemplate";
import LoginForm from "components/login/LoginForm";
import { CheckUserAuthenticated } from "components/common";

const Login = ({ contentRef }) => {
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckUserAuthenticated>
      <LoginTemplate>
        <LoginForm />
      </LoginTemplate>
    </CheckUserAuthenticated>
  );
};

export default Login;
