import React from "react";

const Icon = ({
  name,
  fullPath = false,
  width,
  height,
  marginTop = -4,
  objectFit = "fill",
  className = "",
}) => {
  return (
    <img
      src={fullPath ? `${name}.svg` : `/assets/images/icons/${name}.svg`}
      width={width || height || 24}
      height={height || width || 24}
      alt="Icon"
      className={className}
      style={{
        verticalAlign: "middle",
        marginTop,
        objectFit,
        transition: "all 200ms ease-in-out",
      }}
    />
  );
};

export default Icon;
