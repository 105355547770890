import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "pages/templates/LoggedInTemplate";
import TherapeuticAreaInfo from "components/therapeutic-areas/TherapeuticAreaInfo";

const TherapeuticArea = ({ contentRef }) => {
  // const user = useContext(Context);
  const { therapeuticAreaSlug } = useParams();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [therapeuticAreaSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoggedInTemplate>
      <TherapeuticAreaInfo key={therapeuticAreaSlug} contentRef={contentRef} />
    </LoggedInTemplate>
  );
};

export default TherapeuticArea;
