import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "utils/config";
import { useAuthHeader } from "react-auth-kit";
import { DialogExternalLink } from "components/common";
import $ from "jquery";
window.$ = $;
window.jQuery = $;

const ArticleContent = ({ content }) => {
  const [processedContent, setProcessedContent] = useState();
  const [href, setHref] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const authHeader = useAuthHeader();

  const containerRef = useRef(null);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setHref(null);
  };

  useEffect(() => {
    const container = document.getElementById("article-content");

    $(container).off();

    // const scripts = container.getElementsByTagName("script");
    // for (let i = 0; i < scripts.length; i++) {
    //   $.globalEval(scripts[i].innerHTML);
    // }

    const executeScripts = (container) => {
      const scripts = container.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        $.globalEval(scripts[i].innerHTML);

        // const script = scripts[i];
        // const newScript = document.createElement("script");
        // if (script.src) {
        //   newScript.src = script.src;
        // } else {
        //   newScript.textContent = script.innerHTML;
        // }
        // document.body.appendChild(newScript);
        // document.body.removeChild(newScript); // Remove the script to prevent duplicates
      }
    };

    executeScripts(container);

    return () => {
      $(container).off();
    };
  }, [processedContent]);

  const replaceImagesWithPromises = async (content) => {
    const imgRegex = /<img.*?src="(.*?)"/gi;

    // Find all matches of the regex
    const matches = [...content.matchAll(imgRegex)];

    // Process each match
    const promises = matches.map(async (match) => {
      const originalSrc = match[1];
      if (originalSrc.startsWith("http") || originalSrc.startsWith("https")) {
        // Skip the API call and keep the originalSrc if it already contains http or https
        return { originalSrc, newSrc: originalSrc };
      } else {
        try {
          const response = await axios.get(
            `${config.API_URL}/assets/${originalSrc}`,
            {
              headers: { Authorization: authHeader() },
            }
          );
          const imageData = response.data;
          const newSrc = `data:${imageData.file_type};base64,${imageData.content}`;
          return { originalSrc, newSrc };
        } catch (error) {
          console.error("Error fetching image:", error);
          return { originalSrc, newSrc: originalSrc }; // Fallback to original src on error
        }
      }
    });

    // Await all promises and replace in content
    const results = await Promise.all(promises);
    let processedContent = content;
    results.forEach(({ originalSrc, newSrc }) => {
      processedContent = processedContent.replace(originalSrc, newSrc);
    });

    return processedContent;
  };

  useEffect(() => {
    const replaceImages = async () => {
      const processed = await replaceImagesWithPromises(content);
      setProcessedContent(processed);
    };

    replaceImages();

    const handleClick = (event) => {
      const link = event.target.closest("a");
      if (
        link &&
        containerRef.current.contains(link) &&
        (link.getAttribute("target") === "_blank" ||
          !link.getAttribute("href").startsWith(window.location.origin))
      ) {
        event.preventDefault(); // Prevent the default navigation
        setHref(link.getAttribute("href"));
        setOpenDialog(true);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        id="article-content"
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: processedContent }}
      />
      <DialogExternalLink
        open={openDialog}
        onClose={handleCloseDialog}
        href={href}
      />
    </>
  );
};

export default ArticleContent;
