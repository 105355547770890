import React from "react";
import { Box, Button } from "@mui/material";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { format } from "date-fns";

const ArticleGeneratePDF = ({ article }) => {
  const generatePDF = async () => {
    const element = document.getElementById("content-to-capture");

    if (!element) {
      console.error("No element found with id 'content-to-capture'");
      return;
    }

    try {
      // Ensure all fonts are loaded before capturing the canvas
      await document.fonts.ready;

      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const padding = 15; // Padding for content
      const usableHeight = pageHeight - 2 * padding; // Usable area for content

      // Capture the container as a canvas
      const canvas = await html2canvas(element, {
        scale: 2, // High resolution
        useCORS: true, // Handle cross-origin resources
        backgroundColor: "#ffffff", // Ensure a solid background
        // onclone: (clonedDoc) => {
        //   document.fonts.ready.then(() => clonedDoc.fonts.load('1 em "Poppins, sans-serif"'));
        // },
      });

      const contentHeight = canvas.height;
      const contentWidth = canvas.width;

      // Scale canvas content to fit within the PDF page width
      const scaledWidth = pageWidth - 2 * padding;
      // const scaledHeight = (scaledWidth * contentHeight) / contentWidth;

      let yOffset = 0; // Tracks the vertical position in the content
      let isContentRendered = false; // Flag to track whether content was added to the PDF

      while (yOffset < contentHeight) {
        // Calculate the height of the current slice
        const sliceHeight = Math.min(
          contentHeight - yOffset,
          usableHeight * (contentWidth / scaledWidth)
        );

        // Create a temporary canvas for the current page
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = contentWidth;
        pageCanvas.height = sliceHeight;

        const pageContext = pageCanvas.getContext("2d");
        pageContext.drawImage(
          canvas,
          0,
          yOffset,
          contentWidth,
          sliceHeight,
          0,
          0,
          contentWidth,
          sliceHeight
        );

        // Convert the canvas to an image
        const imgData = pageCanvas.toDataURL("image/png");

        // Add the image to the PDF
        pdf.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          (sliceHeight * scaledWidth) / contentWidth
        );

        yOffset += sliceHeight; // Move to the next slice
        isContentRendered = true; // Set content as rendered

        // Only add a new page if there's more content to render
        if (yOffset < contentHeight) {
          pdf.addPage();
        }
      }

      // Prevent saving the PDF if no content was rendered (edge case)
      if (isContentRendered) {
        const filename = `${format(new Date(), "yyyy-MM-dd_HH:mm")}_${
          article.slug
        }.pdf`;
        pdf.save(filename);
      } else {
        console.error("No content rendered into the PDF.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Box textAlign="center">
      <Button
        variant="contained"
        color="primary"
        startIcon={<DocumentTextIcon width={20} />}
        sx={{ paddingX: 3 }}
        onClick={generatePDF}
      >
        Generează PDF
      </Button>
    </Box>
  );
};

export default ArticleGeneratePDF;
