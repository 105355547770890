import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import theme from "utils/theme";

const FormErrorMessage = (props) => {
  return props.children ? (
    <Stack direction="row" alignItems="flex-start" marginTop={1}>
      <Box paddingLeft={2} paddingRight={1} flexGrow={0}>
        <ExclamationCircleIcon
          style={{
            color: props.warning
              ? theme.palette.warning.main
              : theme.palette.error.main,
            width: "16px",
            marginTop: "3px",
          }}
        />
      </Box>
      <Box flexGrow={1} paddingRight={2} paddingTop={0.25}>
        <Typography
          variant="body2"
          paragraph
          style={{
            marginBottom: 0,
            paddingTop: 6,
            color: props.warning
              ? theme.palette.warning.main
              : theme.palette.error.main,
          }}
        >
          {props.children}
        </Typography>
      </Box>
    </Stack>
  ) : null;
};

export default FormErrorMessage;
