import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Link,
  Tabs,
  Tab,
  TextField,
  RadioGroup,
  Radio,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
  ClockIcon,
  MicrophoneIcon,
  ChatBubbleLeftRightIcon,
  NewspaperIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import theme from "utils/theme";
import JWPlayer from "@jwplayer/jwplayer-react";
import axios from "axios";
import { Form, Formik, Field } from "formik";
import { useAuthHeader } from "react-auth-kit";
import config from "utils/config";
import { scrollbarStyles } from "utils/config-design";
import {
  GetContentTherapeuticAreas,
  GetArticles,
  GetMedia,
  GetEventStatus,
} from "utils/api/getContent";
import { Icon, ButtonExternalLink } from "components/common";
import appContext from "utils/context";
import { mediaBackgroundAPI } from "utils/media";
import { format } from "date-fns";
import ro from "date-fns/locale/ro";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import useUserTracking from "utils/useTracking";
import Error404 from "pages/Error404";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`event-tabpanel-${index}`}
      aria-labelledby={`event-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 2 }}>{children}</Box>}
    </Box>
  );
};

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

const a11yProps = (index) => {
  return {
    id: `event-tab-${index}`,
    "aria-controls": `event-tabpanel-${index}`,
    sx: {
      padding: theme.spacing(0.5, 1),
      minWidth: "auto",
      minHeight: 38,
      textTransform: "initial",
      color: "secondary.darker",
      fontWeight: 300,
      fontSize: "1rem",
      letterSpacing: "-0.025rem",
      "&.Mui-selected": {
        padding: theme.spacing(0.5, 1),
        color: "secondary.darker",
        fontWeight: 400,
      },
    },
  };
};

const ProductButton = ({ label, href }) => {
  return (
    <ButtonExternalLink
      fullWidth
      component={Link}
      href={href}
      target="_blank"
      rel="noreferrer"
      variant="outlined"
      endIcon={
        <Box position="relative">
          <ArrowLongRightIcon width={24} />
          <Box
            sx={{
              position: "absolute",
              width: 18,
              height: 18,
              borderRadius: "50%",
              border: `1px solid ${"secondary.darker"}`,
              top: "50%",
              transform: "translateY(-50%)",
              right: "-4px",
            }}
          />
        </Box>
      }
      sx={{
        flex: 1,
        borderColor: "secondary.darker",
        height: 38,
        "& .MuiButton-endIcon": {
          transition: "all 200ms ease-in-out",
          overflow: "hidden",
          width: 0,
        },
        "&:hover": {
          borderColor: "secondary.darker",
          "& .MuiButton-endIcon": {
            width: 28,
          },
        },
        color: "#110720",
      }}
    >
      {label}
    </ButtonExternalLink>
  );
};

const buttonOutlineStyles = {
  minWidth: 120,
  borderRadius: "12.5rem",
  border: "0.5px solid rgba(255, 255, 255, 0.25) !important",
  background:
    "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
  "&:hover": {
    boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
  },
};

const ProductButtonLive = ({ label, href }) => {
  return (
    <ButtonExternalLink
      fullWidth
      component={Link}
      href={href}
      target="_blank"
      rel="noreferrer"
      variant="outlined"
      sx={buttonOutlineStyles}
    >
      {label}
    </ButtonExternalLink>
  );
};

const EventDate = ({ event }) => {
  const eventDateStyles = {
    fontWeight: 400,
    background:
      "linear-gradient(316deg, #110720 29.19%, #301137 73.82%, #2C1339 78.95%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
  };

  return (
    <Box
      width={{ xs: "auto", md: "100%" }}
      sx={{
        borderRadius: "0.5rem",
        overflow: "hidden",
        border: "1px solid #110720",
      }}
    >
      <Stack
        divider={
          <Box
            sx={{
              width: "100%",
              height: "1px",
              background: "#110720",
            }}
          />
        }
      >
        <Box textAlign="center" padding={1}>
          <Typography
            sx={{
              textTransform: "uppercase",
              ...eventDateStyles,
            }}
          >
            {format(new Date(event.date), "dd MMM yyyy", {
              locale: ro,
            })}
          </Typography>
        </Box>
        <Box padding={1}>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={0.5}
              >
                <ClockIcon
                  width={18}
                  style={{
                    color: theme.palette.secondary.darkest,
                    marginTop: -2,
                  }}
                />
                <Typography
                  sx={{
                    ...eventDateStyles,
                  }}
                >
                  {format(new Date(event.date), "HH:mm")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={0.5}
              >
                <MicrophoneIcon
                  width={18}
                  style={{
                    color: theme.palette.secondary.darkest,
                    marginTop: -2,
                  }}
                />
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    ...eventDateStyles,
                  }}
                >
                  {event.type}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

const EventInfo = () => {
  const {
    therapeuticAreaSlug,
    therapeuticAreaIndicationSlug,
    contentSlug,
    brandSlug,
    tabSlug,
    articleSlug,
  } = useParams();

  const { userTrack } = useUserTracking({
    page: articleSlug,
    therapeutic_area: therapeuticAreaSlug,
    therapeutic_area_indication: therapeuticAreaIndicationSlug,
    brand: brandSlug,
    content_type: tabSlug ?? "about",
  });

  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();
  const therapeuticArea = therapeuticAreasData
    ? therapeuticAreasData[therapeuticAreaSlug]
    : null;
  const therapeuticAreaIndication = therapeuticArea?.indications.find(
    (indication) => indication.slug === therapeuticAreaIndicationSlug
  );
  const product = therapeuticAreaIndication?.brands.find(
    (brand) => brand.slug === brandSlug
  );
  const navigate = useNavigate();

  const authHeader = useAuthHeader();

  const tabSlugs = [undefined, "speakers", "archive"];

  // TABS
  const [eventTab, setEventTab] = useState(
    tabSlugs.findIndex((item) => item === tabSlug) || 0
  );
  function changeProductTab(event, newTab) {
    // console.log("change product tab", newTab);
    const newTabSlug =
      eventStatus.speakers?.length > 0
        ? tabSlugs[newTab]
        : newTab === 1
        ? tabSlugs[newTab + 1]
        : tabSlugs[newTab];
    navigate(
      `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/${contentSlug}/${articleSlug}/${
        newTab !== 0 ? newTabSlug : ""
      }`
    );
    userTrack({
      page: articleSlug,
      therapeutic_area: therapeuticAreaSlug,
      therapeutic_area_indication: therapeuticAreaIndicationSlug,
      brand: brandSlug,
      content_type: newTab !== 0 ? newTabSlug : "about",
    });
    // setEventTab(newTab);
  }
  useEffect(() => {
    if (eventStatus?.speakers?.length > 0) {
      setEventTab(tabSlugs.findIndex((item) => item === tabSlug) || 0);
    } else {
      if (tabSlug === "archive") {
        setEventTab(1);
      } else {
        setEventTab(tabSlugs.findIndex((item) => item === tabSlug) || 0);
      }
    }
  }, [tabSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  // CONTENT
  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const {
    data: eventStatus,
    isErrorEventStatus,
    isPendingEventStatus,
  } = GetEventStatus(articleSlug);

  const { setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(
      eventStatus?.event_status !== "live" &&
        eventStatus?.event_status !== "feedback"
    );

    return () => {
      setIsWhiteContentPage(false);
    };
  }, [eventStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const TabPanelAbout = ({ event }) => {
    const {
      data: eventImage,
      isError,
      isPending,
    } = GetMedia(event.image_id || event.post_image_id);

    return (
      <TabPanel value={eventTab} index={0} paddingTop={2}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={5}>
            {!isError && !isPending && (
              <Box marginBottom={1}>
                <img
                  src={`data:${eventImage.file_type};base64,${eventImage.content}`}
                  alt={event.title}
                  style={{ width: "100%", borderRadius: "0.75rem" }}
                />
              </Box>
            )}
            {event.description && (
              <Typography variant="body2" sx={{ lineHeight: "1.5rem" }}>
                {event.description}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            {event.agenda.length > 0 && (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    marginBottom: 2,
                    paddingLeft: "0.75rem",
                  }}
                >
                  AGENDA
                </Typography>
                <Stack spacing={1.5}>
                  {event.agenda.map((agendaItem, index) => (
                    <Box
                      key={`agenda-item-${index}`}
                      sx={{
                        ...(agendaItem.type === "heading"
                          ? {
                              background: "#E7E7E7",
                              padding: "0.625rem 0.75rem",
                            }
                          : {}),
                      }}
                    >
                      <Stack direction="row" alignItems="start" spacing={2}>
                        {agendaItem.type === "item" && (
                          <Box
                            sx={{
                              width: 100,
                              minWidth: 100,
                              paddingLeft: "0.75rem",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 400 }}
                            >
                              {agendaItem.hour}
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 400,
                              marginBottom: "0 !important",
                            }}
                          >
                            {agendaItem.title}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            {agendaItem.content}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const EventSpeaker = ({ speaker }) => {
    const {
      data: speakerImage,
      isError,
      isPending,
    } = GetMedia(speaker.image_id);

    return (
      <Stack direction="row" alignItems="center" spacing={3}>
        <Box
          sx={{
            width: 88,
            height: 88,
            borderRadius: "50%",
            overflow: "hidden",
            ...mediaBackgroundAPI({
              type: "event-page",
              item: speaker,
              image: speakerImage,
              isPending,
              isError,
              fallbackImage: "url(/assets/images/profile.svg)",
            }),
          }}
        />
        <Stack spacing={0.25}>
          <Typography
            variant="body2"
            sx={{ color: "#8D8F8F", marginBottom: "0 !important" }}
          >
            {speaker.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 400,
              background:
                "linear-gradient(206deg, #2A1150 23.8%, #290E2F 60.19%, #291236 99.55%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              marginBottom: "0 !important",
            }}
          >
            {speaker.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#8D8F8F", marginBottom: "0 !important" }}
          >
            {speaker.institution}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const TabPanelSpeakers = ({ event }) => {
    return (
      event.speakers.length > 0 && (
        <TabPanel value={eventTab} index={1} paddingTop={1}>
          <Grid container spacing={4}>
            {event.speakers.map((speaker, index) => (
              <Grid item xs={12} sm={6} key={`speaker-${index}`}>
                <EventSpeaker speaker={speaker} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      )
    );
  };

  const TabPanelArchive = ({ event }) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoArchiveContainerRef = useRef(null);

    const [playlistHeight, setPlaylistHeight] = useState(430);

    useLayoutEffect(
      () => {
        if (
          playlistHeight !== videoArchiveContainerRef?.current?.clientHeight
        ) {
          setPlaylistHeight(
            videoArchiveContainerRef?.current?.clientHeight || 430
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        videoArchiveContainerRef.current,
        videoArchiveContainerRef.current?.clientHeight,
        window.innerWidth,
      ]
    );

    return (
      <TabPanel
        value={eventTab}
        index={event.speakers?.length > 0 ? 2 : 1}
        paddingTop={1}
      >
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
            <Box
              width="100%"
              sx={{ aspectRatio: 16 / 9 }}
              ref={videoArchiveContainerRef}
            >
              <JWPlayer
                key={`event-archive-video-${currentVideoIndex}`}
                id={`event-archive-video-${currentVideoIndex}`}
                file={event.event_archive[currentVideoIndex].post_event_link}
                image={
                  event.event_archive[currentVideoIndex].placeholder_post_event
                }
                library="https://content.jwplatform.com/libraries/ktd5pJhX.js"
                config={{
                  key: "en/aXi8ObIYnCZO0QZ8O2fioatD9DEwZkVt4ADV1MgY=",
                  primary: "html5",
                  hlshtml: true,
                  width: "100%",
                  autostart: false,
                  aspectratio: "16:9",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
            <Box
              sx={{
                width: "100%",
                // height: player?.getHeight() - 105 || 300,
                height: {
                  xs: 300,
                  sm: playlistHeight,
                },
                paddingRight: 1,
                overflowY: "auto",
                ...scrollbarStyles,
              }}
            >
              {event.event_archive.map((archive, index) => (
                <Box
                  key={`archive-${index}`}
                  sx={{
                    cursor: "pointer",
                    padding: 2,
                    transition: "all 200ms ease-in-out",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.gray,
                    },
                    ...(currentVideoIndex === index
                      ? {
                          backgroundColor: theme.palette.primary.darker,
                        }
                      : {}),
                  }}
                  onClick={() => {
                    setCurrentVideoIndex(index);
                    // setPlayerId(`event-archive-video-${index}`);
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={12} lg={4}>
                      <img
                        src={archive.placeholder_post_event}
                        alt={archive.title}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={8} md={12} lg={8}>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            marginBottom: "0 !important",
                            fontWeight: "500",
                          }}
                        >
                          {archive.title}
                        </Typography>
                      </Box>
                      {archive.speakers &&
                        archive.speakers.map((speaker, indexSpeaker) => (
                          <Typography
                            variant="body3"
                            sx={{
                              display: "block",
                              marginBottom: "0.125rem !important",
                            }}
                            key={`archive-${index}-speaker-${indexSpeaker}`}
                          >
                            {[speaker.title, speaker.name].join(" ")}
                          </Typography>
                        ))}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} order={3}>
            <Box>
              <Typography variant="h2">
                {event.event_archive[currentVideoIndex].title}
              </Typography>
            </Box>
            {event.event_archive[currentVideoIndex].description && (
              <Box marginBottom={3}>
                <Typography variant="body">
                  {event.event_archive[currentVideoIndex].description}
                </Typography>
              </Box>
            )}
            <Grid container spacing={4} sx={{ marginBottom: 1 }}>
              {event.event_archive[currentVideoIndex].speakers &&
                event.event_archive[currentVideoIndex].speakers.map(
                  (speaker, index) => (
                    <Grid item xs={12} sm={6} md={4} key={`speaker-${index}`}>
                      <EventSpeaker speaker={speaker} />
                    </Grid>
                  )
                )}
            </Grid>
            <Box marginTop={2}>
              <Typography variant="body3" color="primary.gray3">
                {event.event_archive[currentVideoIndex].approval_code}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const EventImage = ({ event }) => {
    const {
      data: eventImage,
      isError,
      isPending,
    } = GetMedia(event.image_id || event.post_image_id);

    return (
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          height: { xs: 160, sm: 280 },
          ...mediaBackgroundAPI({
            type: "event-page",
            item: event,
            image: eventImage,
            isPending,
            isError,
          }),
          // background: getImage(`banner-${brandSlug}`)
          //   ? `url(${getImage(
          //       `banner-${brandSlug}`
          //     )}) #0c0820 no-repeat center`
          //   : "transparent",
          // backgroundSize: "contain",
        }}
      />
    );
  };

  const { windowWidth } = useWindowAspectRatio();

  const EventPre = ({ event, isPost = false }) => {
    const productSidebarRef = useRef(null);
    const [sidebarWidth, setSidebarWidth] = useState(null); // Initial width

    useEffect(() => {
      const handleResize = () => {
        // console.log("productSidebarRef.current", productSidebarRef.current);
        if (productSidebarRef.current) {
          setSidebarWidth(productSidebarRef.current.clientWidth - 32);
        }
      };
      handleResize();
      window.setTimeout(() => {
        handleResize();
      }, 500);

      // Add event listener on component mount
      window.addEventListener("resize", handleResize);

      // Cleanup function to remove listener on unmount
      return () => window.removeEventListener("resize", handleResize);
    }, [productSidebarRef]); // Dependency array to trigger recalculation on ref changes

    const { windowWidth } = useWindowAspectRatio();

    return (
      <Box
        sx={{
          background: {
            xs: "linear-gradient(180deg, transparent 99px, rgba(244,245,247,1) 99px)",
            sm: "linear-gradient(180deg, transparent 219px, rgba(244,245,247,1) 219px)",
          },
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <EventImage event={event} />
        <Container
          maxWidth="xl"
          sx={{
            position: "relative",
            paddingTop: {
              xs: "45px",
              sm: "40px",
              md: "80px",
            },
            paddingBottom: {
              xs: "20px",
              sm: "140px",
              md: "120px",
              lg: "100px",
            },
            height: windowWidth >= 1280 ? "100%" : "auto",
            minHeight: windowWidth >= 1280 ? "100%" : "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={4}
            height={windowWidth >= 1280 ? "100%" : "auto"}
            flexGrow={1}
            alignItems="stretch"
            marginBottom={4}
          >
            <Grid item xs={12} md={4} lg={3} ref={productSidebarRef}>
              <Box
                marginY={2}
                display={{ xs: "block", md: "none" }}
                textAlign={{ xs: "center", sm: "left" }}
              >
                <Button
                  component={RouterLink}
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/events`}
                  sx={{ fontSize: "1rem" }}
                >
                  Înapoi
                </Button>
              </Box>
              <Box
                sx={{
                  height: windowWidth >= 1280 ? "calc(100vh - 270px)" : "auto",
                  position:
                    sidebarWidth === null || windowWidth < 1280
                      ? "relative"
                      : "fixed",
                  width: sidebarWidth || "100%",
                  marginTop: 0,
                  borderRadius: "0.75rem",
                  padding: 3,
                  background: theme.palette.primary.main,
                  color: "primary.black",
                  boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.20)",
                }}
              >
                <Stack gap={3} height={{ xs: "auto", md: "100%" }}>
                  <Box flexGrow={1}>
                    <Stack
                      spacing={4}
                      alignItems="center"
                      direction={{ xs: "column", sm: "row", md: "column" }}
                      width="100%"
                    >
                      {event.date && <EventDate event={event} />}
                      <Box
                        textAlign={{ xs: "center", sm: "left", md: "center" }}
                      >
                        <Box marginBottom={{ xs: 0, md: 2 }}>
                          <Typography variant="h1" sx={{ fontWeight: 400 }}>
                            {event.title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#828282", lineHeight: "1.5rem" }}
                          >
                            {event.excerpt}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack
                      gap={2}
                      direction={{ xs: "column", sm: "row", md: "column" }}
                      justifyContent="space-between"
                    >
                      <Stack
                        sx={{
                          borderRadius: "0.75rem",
                          border: "1px solid rgba(229, 229, 229, 0.98)",
                          background: "#FAF9FB",
                          marginBottom: { xs: 2, sm: 0, md: 2 },
                          minWidth: { xs: "auto", sm: "35%", md: "auto" },
                        }}
                        divider={
                          <Box
                            sx={{
                              height: "1px",
                              width: "100%",
                              backgroundColor: "rgba(229, 229, 229, 0.98)",
                            }}
                          />
                        }
                      >
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            variant="body3"
                            sx={{ color: "primary.black" }}
                          >
                            Organizat de:
                          </Typography>
                          <Box height={80} marginX="auto">
                            <Icon
                              name={`/assets/images/products/color/${product.slug}`}
                              fullPath={true}
                              height="100%"
                              objectFit="contain"
                            />
                          </Box>
                        </Box>
                        <Box paddingX={2} paddingY={1}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={0.5}
                          >
                            <Icon
                              name={`indicatii/gradient/${therapeuticAreaIndication.slug}`}
                              width={16}
                              marginTop={0}
                            />
                            <Typography
                              variant="body3"
                              style={{
                                background:
                                  "linear-gradient(90deg, #B80062 0%, #6F1E9A 100%)",
                                backgroundClip: "text",
                                WebkitBackgroundClip: "text",
                                color: "transparent",
                              }}
                              component="span"
                            >
                              {therapeuticAreaIndication.title}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      <Stack
                        gap={2}
                        minWidth={{ xs: "auto", sm: "50%", md: "auto" }}
                      >
                        {product.external_url && (
                          <Stack gap={1.5}>
                            {product.external_url
                              .split(";")
                              .map((ext_url, index) => (
                                <ProductButton
                                  key={`ext_url_${index}`}
                                  // label={`Vezi pagina ${product.name}`}
                                  label={ext_url
                                    .replace("http://", "")
                                    .replace("https://", "")}
                                  href={ext_url}
                                />
                              ))}
                          </Stack>
                        )}
                        {product.rcp_url?.length > 0 && (
                          <Grid container spacing={2}>
                            {product.rcp_url.map((rcp, index) => (
                              <Grid
                                item
                                key={`RCP-${index + 1}`}
                                xs={product.rcp_url?.length === 1 ? 12 : 6}
                              >
                                <ProductButton
                                  label={
                                    rcp.rcp_name
                                      ? rcp.rcp_name
                                      : product.rcp_url.length > 1
                                      ? `RCP ${index + 1}`
                                      : "RCP"
                                  }
                                  href={rcp.url}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}
                        {product.therapeutic_protocol_url && (
                          <Box>
                            <ProductButton
                              label="Protocol Terapeutic"
                              href={product.therapeutic_protocol_url}
                            />
                          </Box>
                        )}
                        {product.prescription_form_url &&
                          product.prescription_form_url.map(
                            (prescription, index) => (
                              <Box key={`prescription-${index + 1}`}>
                                <ProductButton
                                  label={
                                    prescription.prescription_name ||
                                    "Formular prescriere"
                                  }
                                  href={prescription.url}
                                />
                              </Box>
                            )
                          )}
                        {event.approval_code && (
                          <Box textAlign="center">
                            <Typography
                              variant="body3"
                              sx={{
                                marginBottom: 0,
                              }}
                            >
                              {event.approval_code}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Box paddingTop={12} display={{ xs: "none", md: "block" }}>
                <Button
                  component={RouterLink}
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/events`}
                  sx={{ fontSize: "1rem" }}
                >
                  Înapoi
                </Button>
              </Box>
              <Box
                paddingTop={{ xs: 3, sm: 0, md: 3 }}
                sx={{ color: "primary.black" }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "secondary.lighter" }}>
                  <Tabs
                    value={eventTab}
                    onChange={changeProductTab}
                    aria-label="Tab-uri produs"
                    sx={{
                      minHeight: 38,
                      "& .MuiTabs-indicator": {
                        background: theme.palette.secondary.darker,
                      },
                    }}
                  >
                    <Tab label="Despre" {...a11yProps(0)} />
                    {event.speakers?.length > 0 && (
                      <Tab label="Speakeri" {...a11yProps(1)} />
                    )}
                    {event.event_archive?.length > 0 && (
                      <Tab
                        label="Arhivă"
                        {...a11yProps(event.speakers?.length > 0 ? 2 : 1)}
                      />
                    )}
                  </Tabs>
                </Box>
                <TabPanelAbout event={event} />
                <TabPanelSpeakers event={event} />
                {event.event_archive?.length > 0 && (
                  <TabPanelArchive event={event} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const EventVideoPlayer = ({ event, isMuted }) => {
    // console.log("EventVideoPlayer", event.streaming_url);

    // JWPLAYER
    const [playerId, setPlayerId] = useState("live-event");
    // const [player, setPlayer] = useState(null);
    const handleVideoDidMountCallback = (event) => {
      // console.log("video handleVideoDidMountCallback", event);
      // setPlayer(event.player);
      setPlayerId(event.id);
    };

    return (
      <>
        {event.streaming_html && (
          <Box
            sx={{
              borderRadius: "0.75rem",
              background: "rgba(0, 0, 0, 0.35)",
              overflow: "hidden",
              position: "relative",
              width: "100%",
              // minHeight: 300,
              // aspectRatio: "16:9",
              "& iframe": {
                width: "100%",
                height: "auto",
                aspectRatio: "16/9",
                display: "block",
              },
            }}
            dangerouslySetInnerHTML={{
              __html: event.streaming_html,
            }}
          />
        )}
        {event.streaming_url && (
          <Box
            sx={{
              borderRadius: "0.75rem",
              overflow: "hidden",
            }}
          >
            <JWPlayer
              id={playerId}
              file={event.streaming_url}
              library="https://content.jwplatform.com/libraries/ktd5pJhX.js"
              config={{
                key: "en/aXi8ObIYnCZO0QZ8O2fioatD9DEwZkVt4ADV1MgY=",
                primary: "html5",
                hlshtml: true,
                width: "100%",
                autostart: true,
                mute: isMuted,
                aspectratio: "16:9",
              }}
              didMountCallback={handleVideoDidMountCallback}
            />
          </Box>
        )}
      </>
    );
  };

  const EventLive = ({ event, isMuted = false }) => {
    // TABS
    const [tab, setTab] = useState("question");

    const videoContainerRef = useRef(null);
    // console.log(videoContainerRef?.current?.clientHeight);

    // SEND QUESTION
    const [question, setQuestion] = useState("");
    const [questionIsSending, setQuestionIsSending] = useState(false);
    const [questionSendStatus, setQuestionSendStatus] = useState("");

    const handleChangeQuestion = (event) => {
      setQuestion(event.target.value);
    };

    const handleSubmitQuestion = async (event) => {
      event.preventDefault(); // Prevent default form submission behavior

      // console.log("question sent", question);
      setQuestionIsSending(true);
      try {
        const responseQuestionSend = await axios.post(
          `${config.API_URL}/events/${articleSlug}/send-question`,
          {
            question,
          },
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        // console.log(responseQuestionSend);
        if (responseQuestionSend.data.success === "Question has been sent") {
          setQuestion("");
          setQuestionSendStatus("Întrebarea a fost trimisă cu succes.");
        } else {
          setQuestionSendStatus(config.ERRORS.API_ERROR_EVENT_LIVE);
        }
      } catch (error) {
        console.error("Error sending data", error);
        setQuestionSendStatus(config.ERRORS.API_ERROR_EVENT_LIVE);
      }
      setQuestionIsSending(false);
    };

    const LiveAndDateAndTitle = ({ alignItems = "start" }) => {
      return (
        <Stack
          direction="column"
          flexGrow={1}
          alignItems={alignItems}
          gap={0.5}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            marginBottom={{ xs: 1, sm: 0 }}
            divider={
              <Box
                sx={{
                  width: "1px",
                  minWidth: "1px",
                  opacity: 0.6,
                  height: "1rem",
                  background:
                    "linear-gradient(0deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 0.60) 50%, rgba(254, 254, 254, 0) 100%)",
                }}
              />
            }
          >
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={0.25}
              >
                <MicrophoneIcon
                  width={14}
                  style={{
                    color: theme.palette.warning.yellow,
                  }}
                />
                <Typography
                  variant="body3"
                  sx={{
                    textTransform: "uppercase",
                    color: theme.palette.warning.yellow,
                  }}
                >
                  LIVE
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Typography
                variant="body3"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {format(new Date(event.date), "dd MMM yyyy", {
                  locale: ro,
                })}
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 400,
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {event.title}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "1rem", lg: "1.25rem" },
                lineHeight: 1.5,
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {event.excerpt}
            </Typography>
          </Box>
        </Stack>
      );
    };

    return (
      <Container
        maxWidth="xl"
        sx={{
          paddingBottom: {
            xs: "40px",
            sm: "180px",
            md: "160px",
            lg: "140px",
          },
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            background: "#434C84",
          }}
        />
        <Box
          marginTop={3}
          marginBottom={1}
          textAlign={{ xs: "center", sm: "left" }}
        >
          <Button
            component={RouterLink}
            variant="text"
            startIcon={<ArrowLongLeftIcon width={20} />}
            to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/${contentSlug}`}
            sx={{ fontSize: "0.875rem" }}
          >
            Înapoi la Evenimente
          </Button>
        </Box>
        {windowWidth < 600 && (
          <Box>
            <Box marginBottom={2}>
              <Box height="100%" ref={videoContainerRef}>
                <EventVideoPlayer event={event} isMuted={isMuted} />
              </Box>
            </Box>
            <Box marginBottom={3}>
              <LiveAndDateAndTitle alignItems="center" />
            </Box>
          </Box>
        )}
        <Stack direction="column" gap={3}>
          <Stack
            direction="row"
            alignItems={{ xs: "center", sm: "stretch" }}
            gap={{ xs: 0, sm: 2, md: 3, lg: 5 }}
            sx={{
              borderRadius: "0.75rem",
              background: "rgba(0, 0, 0, 0.35)",
              boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
            }}
            divider={
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                  width: "1px",
                  opacity: 0.6,
                  background:
                    "linear-gradient(0deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 0.60) 50%, rgba(254, 254, 254, 0) 100%)",
                }}
              />
            }
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={1}
              sx={{
                padding: {
                  xs: theme.spacing(2),
                  sm: theme.spacing(2, 0, 2, 2),
                  md: theme.spacing(3, 0, 3, 3),
                  lg: theme.spacing(4, 1, 4, 6),
                },
                alignSelf: "center",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Stack direction="row" alignItems="center" gap={2.5}>
                <Box
                  height={80}
                  component={RouterLink}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`}
                >
                  <Icon
                    name={`/assets/images/products/white/${product.slug}`}
                    fullPath={true}
                    height="100%"
                    objectFit="contain"
                  />
                </Box>
                <Box
                  component={RouterLink}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}`}
                >
                  <Icon
                    name={`indicatii/white/${therapeuticAreaIndication.slug}`}
                    width={40}
                    marginTop={0}
                  />
                </Box>
              </Stack>
              <Box>
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="primary"
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`}
                  sx={{ textAlign: "center" }}
                >
                  Vezi pagina {product.name}
                </Button>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Stack direction="row" gap={2}>
                  {product.therapeutic_protocol_url && (
                    <Box>
                      <ProductButtonLive
                        label="Protocol"
                        href={product.therapeutic_protocol_url}
                      />
                    </Box>
                  )}
                  {product.rcp_url?.length > 0 &&
                    product.rcp_url.map((rcp, index) => (
                      <Box key={`RCP-${index + 1}`}>
                        <ProductButtonLive
                          label={
                            rcp.rcp_name
                              ? rcp.rcp_name
                              : product.rcp_url.length > 1
                              ? `RCP ${index + 1}`
                              : "RCP"
                          }
                          href={rcp.url}
                        />
                      </Box>
                    ))}
                </Stack>
              </Box>
            </Stack>
            <Box
              flexGrow={1}
              sx={{ alignSelf: "center", display: { xs: "none", sm: "block" } }}
            >
              <Stack
                direction="row"
                gap={5}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <LiveAndDateAndTitle />
                <Box
                  sx={{
                    padding: theme.spacing(4, 4, 4, 0),
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Stack direction="column" gap={2}>
                    {product.therapeutic_protocol_url && (
                      <Box>
                        <ProductButtonLive
                          label="Protocol"
                          href={product.therapeutic_protocol_url}
                        />
                      </Box>
                    )}
                    {product.rcp_url?.length > 0 &&
                      product.rcp_url.map((rcp, index) => (
                        <Box key={`RCP-${index + 1}`}>
                          <ProductButtonLive
                            label={
                              rcp.rcp_name
                                ? rcp.rcp_name
                                : product.rcp_url.length > 1
                                ? `RCP ${index + 1}`
                                : "RCP"
                            }
                            href={rcp.url}
                          />
                        </Box>
                      ))}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Grid container spacing={2} alignItems="stretch">
            {windowWidth >= 600 && (
              <Grid item xs={12} md={8}>
                <Box height="100%" ref={videoContainerRef}>
                  <EventVideoPlayer event={event} isMuted={isMuted} />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={4} height={{ xs: 400, sm: "auto" }}>
              <Stack direction="column" height="100%">
                <Box paddingLeft={4}>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <Box
                      sx={{
                        lineHeight: 0,
                        paddingX: 2,
                        paddingY: 0.5,
                        borderRadius: "0.5rem 0.5rem 0rem 0rem",
                        background: `rgba(0, 0, 0, ${
                          tab === "question" ? 0.35 : 0.55
                        })`,
                        cursor: "pointer",
                      }}
                      onClick={() => setTab("question")}
                    >
                      <ChatBubbleLeftRightIcon
                        width={20}
                        style={{
                          marginTop: 0,
                        }}
                      />
                    </Box>
                    {event?.agenda?.length > 0 && (
                      <Box
                        sx={{
                          lineHeight: 0,
                          paddingX: 2,
                          paddingY: 0.5,
                          borderRadius: "0.5rem 0.5rem 0rem 0rem",
                          background: `rgba(0, 0, 0, ${
                            tab === "agenda" ? 0.35 : 0.55
                          })`,
                          cursor: "pointer",
                        }}
                        onClick={() => setTab("agenda")}
                      >
                        <NewspaperIcon
                          width={20}
                          style={{
                            marginTop: 0,
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Box
                  flexGrow={1}
                  sx={{
                    borderRadius: "0.75rem",
                    background: "rgba(0, 0, 0, 0.35)",
                  }}
                >
                  {tab === "question" && (
                    <form
                      onSubmit={handleSubmitQuestion}
                      style={{ height: "100%" }}
                    >
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                        sx={{
                          padding: 3,
                          height: "100%",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 400, marginBottom: "0 !important" }}
                        >
                          Adresează o întrebare speakerului
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={
                            windowWidth >= 1280
                              ? 6
                              : windowWidth >= 1024
                              ? 3
                              : 4
                          }
                          id="intrebare"
                          name="intrebare"
                          value={question}
                          onChange={handleChangeQuestion}
                          disabled={questionIsSending}
                        />
                        <Button
                          type="submit"
                          sx={buttonOutlineStyles}
                          disabled={questionIsSending || question.trim() === ""}
                        >
                          {questionIsSending ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Trimite"
                          )}
                        </Button>
                        {questionSendStatus && question === "" && (
                          <Box marginTop={2}>
                            <Typography>{questionSendStatus}</Typography>
                          </Box>
                        )}
                      </Stack>
                    </form>
                  )}
                  {tab === "agenda" && (
                    <Stack
                      direction="column"
                      height="100%"
                      gap={2}
                      sx={{
                        paddingY: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            paddingLeft: 3,
                          }}
                        >
                          AGENDA
                        </Typography>
                      </Box>
                      <Box flexGrow={1}>
                        <Box
                          sx={{
                            width: "100%",
                            // height: player?.getHeight() - 105 || 300,
                            height: {
                              xs: 300,
                              sm:
                                videoContainerRef?.current?.clientHeight -
                                  120 || 300,
                            },
                            overflowY: "auto",
                            ...scrollbarStyles,
                          }}
                        >
                          <Stack spacing={1.5}>
                            {event.agenda.map((agendaItem, index) => (
                              <Box
                                key={`agenda-item-${index}`}
                                sx={{
                                  paddingX: 3,
                                  ...(agendaItem.type === "heading"
                                    ? {
                                        background: "rgba(255, 255, 255, 0.15)",
                                        paddingY: 1.25,
                                      }
                                    : {}),
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="start"
                                  spacing={2}
                                >
                                  {agendaItem.type === "item" && (
                                    <Box sx={{ width: 120 }}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 400 }}
                                      >
                                        {agendaItem.hour}
                                      </Typography>
                                    </Box>
                                  )}
                                  <Stack direction="column" gap={0.5}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 500,
                                        marginBottom: "0 !important",
                                      }}
                                    >
                                      {agendaItem.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {agendaItem.content}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Box>
                            ))}
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  )}
                </Box>
              </Stack>
              {event.approval_code && (
                <Box textAlign="right" marginTop={1} paddingX={3}>
                  <Typography
                    variant="body3"
                    sx={{
                      marginBottom: 0,
                    }}
                  >
                    {event.approval_code}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    );
  };

  const EventFeedback = ({ event, storedDataEventsFeedback }) => {
    // const [openDialog, setOpenDialog] = useState(true);

    const handleCloseDialog = () => {
      navigate(
        `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`
      );
    };

    const prepareQuestions = (questions) => {
      const array = [];

      for (const key in questions) {
        const question = questions[key];

        array.push({
          id: key,
          label: question.text,
          component: question.type,
          ...(question.options
            ? {
                options: question.options.map((option) => ({
                  value: option.value,
                  label: option.text,
                })),
              }
            : {}),
        });
      }

      // array.push({
      //   id: "q_8",
      //   label: "CHECKBOXES",
      //   component: "checkbox",
      //   options: [
      //     {
      //       value: "1",
      //       label: "1 - Inacceptabil",
      //     },
      //     {
      //       value: "2",
      //       label: "2 - De îmbunătățit",
      //     },
      //     {
      //       value: "3",
      //       label: "3 - Adecvat/bine",
      //     },
      //     {
      //       value: "4",
      //       label: "4 - Excelent",
      //     },
      //   ],
      // });

      // console.log("array", array);

      return array;
    };

    const [feedbackIsSending, setFeedbackIsSending] = useState(false);
    const [feedbackSendStatus, setFeedbackSendStatus] = useState(null);

    const handleSubmit = async (values) => {
      // console.log(values);

      if (Object.keys(values).length === 0) return;

      const questionsAPI = {};

      for (const key in values) {
        questionsAPI[key] = {
          type: prepareQuestions(event.questions).find(
            (question) => question.id === key
          ).component,
          values: [values[key]],
        };
      }

      // console.log("questionsAPI", questionsAPI);

      setFeedbackIsSending(true);
      try {
        const responseFeedbackSend = await axios.post(
          `${config.API_URL}/events/${articleSlug}/send-feedback-questions`,
          questionsAPI,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        // console.log(responseFeedbackSend);
        if (
          responseFeedbackSend.data.message === "Feedback stored successfully"
        ) {
          let azEventsFeedback = storedDataEventsFeedback;
          azEventsFeedback.push(articleSlug);
          try {
            localStorage.setItem(
              "az-events-feedback",
              JSON.stringify(azEventsFeedback)
            );
          } catch (error) {}

          setFeedbackSendStatus(true);
        } else {
          setFeedbackSendStatus(false);
        }
      } catch (error) {
        console.error("Error sending data", error);
        setFeedbackSendStatus(false);
      }
      setFeedbackIsSending(false);
    };

    return (
      <Dialog
        aria-labelledby="event-feedback"
        open={true}
        disableEscapeKeyDown
        maxWidth="lg"
      >
        <DialogTitle sx={{ m: 0, pY: 2, px: 3 }} id="event-feedback">
          Feedback
          {feedbackSendStatus && (
            <CheckCircleIcon
              width={24}
              style={{
                color: theme.palette.success.main,
                marginLeft: 10,
                verticalAlign: "middle",
                marginTop: -2,
              }}
            />
          )}
        </DialogTitle>
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <XMarkIcon
            width={24}
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </IconButton>
        <DialogContent dividers sx={{ ...scrollbarStyles, marginRight: 1 }}>
          {feedbackSendStatus === null || feedbackSendStatus === false ? (
            <>
              <Box marginBottom={3}>
                <Typography>
                  Părerea dvs. este importantă!
                  <br />
                  Vă invităm să evaluați evenimentul nostru, răspunzând la
                  întrebările de mai jos:
                </Typography>
              </Box>
              <Formik initialValues={{}} onSubmit={handleSubmit}>
                {({ values, errors, touched, setFieldValue }) => (
                  <Form disabled={feedbackIsSending}>
                    {prepareQuestions(event.questions).map((question) => (
                      <Field key={question.id} name={question.id}>
                        {({ field }) => (
                          <Box marginBottom={3}>
                            <Box
                              marginBottom={
                                question.component === "textarea" ? 1 : 0
                              }
                            >
                              <Typography sx={{ fontWeight: 400 }}>
                                {question.label}
                              </Typography>
                            </Box>
                            {question.component === "radio" && (
                              <RadioGroup
                                {...field}
                                row
                                value={field.value}
                                onChange={field.onChange}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  columnGap: theme.spacing(5),
                                }}
                              >
                                {question.options.map((option) => (
                                  <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                            {question.component === "checkbox" && (
                              <FormGroup
                                {...field}
                                value={field.value || []} // initialize value as an empty array
                                onChange={(e) => {
                                  // console.log(e);
                                  const newValue =
                                    field.value && field.value.length
                                      ? [...field.value]
                                      : []; // copy current values
                                  if (e.target.checked) {
                                    newValue.push(e.target.id); // add selected value
                                  } else {
                                    newValue.splice(
                                      newValue.indexOf(e.target.id),
                                      1
                                    ); // remove deselected value
                                  }
                                  // console.log("New value", newValue);
                                  setFieldValue(question.id, newValue);
                                }}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: theme.spacing(5),
                                }}
                              >
                                {question.options.map((option) => (
                                  <FormControlLabel
                                    key={option.value}
                                    control={<Checkbox id={option.value} />}
                                    label={option.label}
                                  />
                                ))}
                              </FormGroup>
                            )}
                            {question.component === "textarea" && (
                              <TextField
                                {...field}
                                fullWidth
                                multiline
                                rows={2}
                              />
                            )}
                          </Box>
                        )}
                      </Field>
                    ))}
                    <Box textAlign="center" marginBottom={3}>
                      <Button
                        type="submit"
                        sx={buttonOutlineStyles}
                        disabled={feedbackIsSending}
                      >
                        Trimite
                      </Button>
                      {feedbackSendStatus === false && (
                        <Box marginTop={2}>
                          {config.ERRORS.API_ERROR_EVENT_LIVE}
                        </Box>
                      )}
                    </Box>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <Box>
              <Typography>
                Mulțumim!
                <br /> Răspunsurile dumneavoastră au fost înregistrate.
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  const EventPost = ({ event }) => {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ widht: "100%", height: "100%" }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "60rem",
            marginX: "auto",
            textAlign: "center",
          }}
          marginY={10}
          padding={2}
        >
          <Box
            sx={{
              position: "absolute",
              zIndex: 0,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              borderRadius: "60rem",
              background: "rgba(132, 11, 95, 0.20)",
              filter: "blur(134.5569610595703px)",
            }}
          />
          <Typography variant="h1">Evenimentul căutat s-a încheiat.</Typography>
          <Button
            component={RouterLink}
            variant="contained"
            sx={{ marginTop: 5 }}
            to={`/therapeutic-areas/${therapeuticAreaSlug}`}
          >
            Vezi {therapeuticArea.title}
          </Button>
        </Box>
      </Stack>
    );
  };

  const storedDataEventsFeedback = JSON.parse(
    localStorage.getItem("az-events-feedback") || "[]"
  );

  return therapeuticArea &&
    therapeuticAreasContent &&
    !isPendingEventStatus &&
    eventStatus ? (
    !isErrorEventStatus ? (
      <>
        {eventStatus.event_status === "pre" && <EventPre event={eventStatus} />}
        {eventStatus.event_status === "live" && (
          <EventLive event={eventStatus} />
        )}
        {eventStatus.event_status === "feedback" && (
          <>
            <EventLive event={eventStatus} isMuted={true} />
            {storedDataEventsFeedback.find((item) => item === articleSlug) ===
              undefined && (
              <EventFeedback
                event={eventStatus}
                storedDataEventsFeedback={storedDataEventsFeedback}
              />
            )}
          </>
        )}
        {eventStatus.event_status === "post" && (
          <EventPost event={eventStatus} />
        )}
      </>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default EventInfo;
