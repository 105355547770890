import React from "react";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";
import { Navigate } from "react-router-dom";

const CheckUserAuthenticated = ({
  shouldBeLoggedIn = false,
  unAuthorizeUser = false,
  children,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  if (unAuthorizeUser) {
    signOut();
    return children;
  }

  if (!shouldBeLoggedIn) {
    if (isAuthenticated()) {
      return <Navigate to={"/"} replace />;
    } else {
      return children;
    }
  } else {
    if (isAuthenticated()) {
      return children;
    } else {
      return <Navigate to={"/login"} replace />;
    }
  }
};

export default CheckUserAuthenticated;
