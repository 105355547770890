import React, { useState } from "react";
// import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DialogExternalLink } from "components/common";

const LinkText = ({
  href,
  target,
  color,
  to,
  children,
  underline,
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleLinkClick = (event) => {
    if (
      (target === "_blank" || !href.startsWith(window.location.origin)) &&
      !href.includes("cookiereports.com")
    ) {
      setOpenDialog(true);
      event.preventDefault();
    }
  };

  return to ? (
    <Link color={color || "primary"} component={RouterLink} to={to || "/"}>
      {children}
    </Link>
  ) : (
    <>
      <Link
        color={color || "primary"}
        href={href}
        target={target || "_self"}
        rel={target === "_blank" ? "noreferrer" : null}
        onClick={handleLinkClick}
        underline={underline || "none"}
        {...rest}
      >
        {children}
      </Link>
      <DialogExternalLink
        open={openDialog}
        onClose={handleCloseDialog}
        href={href}
      />
    </>
  );
};

export default LinkText;
