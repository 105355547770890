import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "utils/config";
import Context from "utils/context";

const AppContext = ({ children }) => {
  const [serverTime, setServerTime] = useState(null);
  const [isWhiteContentPage, setIsWhiteContentPage] = useState(false);

  useEffect(() => {
    let initialServerTime = null;
    const getServerStatus = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/healthcheck`);
        // console.log(response);
        if (response.data.status === "ok") {
          setServerTime(response.data.date);
          initialServerTime = response.data.date;
        }
      } catch (err) {
        // setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
        setServerTime(0);
        console.error(err);
      }
    };
    getServerStatus();

    const checkLastUpdate = async (initialServerTime) => {
      try {
        const response = await axios.get(`${config.API_URL}/healthcheck`);
        if (response.data.status === "ok" && initialServerTime) {
          const lastUpdateDate = new Date(response.data.last_update);
          const initialServerTimeDate = new Date(initialServerTime);
          if (lastUpdateDate > initialServerTimeDate) {
            window.location.reload();
          }
        }
      } catch (err) {
        // setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
        console.error(err);
      }
    };
    let checkLastUpdateInterval = setInterval(() => {
      checkLastUpdate(initialServerTime);
    }, 1000 * 60);
    return () => clearInterval(checkLastUpdateInterval);
  }, []);

  return (
    <Context.Provider
      value={{ serverTime, isWhiteContentPage, setIsWhiteContentPage }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
