import React, { useState } from "react";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Typography, Box, Stack } from "@mui/material";
import axios from "axios";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { FormErrorMessage } from "components/common";
import config from "utils/config";
import useUserTracking from "utils/useTracking";

const RecoverPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const recoverPasswordEmail = searchParams.get("email");

  useUserTracking({
    page: "recover-password",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState("reset-password");

  // SEND EMAIL

  const handleSubmitSendEmail = async (values) => {
    // console.log("VALUES", values);

    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        `${config.API_URL}/email/reset-password`,
        {
          user_email: values.email,
        }
      );

      if (response.data.message === "Password reset link sent") {
        setCurrentStep("reset-password-ok");
      } else if (response.data.message === "No user found.") {
        setError(
          "Adresa de e-mail introdusă nu este în baza noastră de date. Asigurați-vă că adresa de e-mail este corect scrisă."
        );
      }
    } catch (err) {
      setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
    } finally {
      setLoading(false);
    }
  };

  const formikSendEmail = useFormik({
    initialValues: {
      email: recoverPasswordEmail || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Adresa de e-mail este obligatorie.")
        .email("Adresa introdusă nu pare a fi corectă."),
    }),
    onSubmit: handleSubmitSendEmail,
  });

  return (
    <div>
      {currentStep === "reset-password" && (
        <>
          <Box
            sx={{
              marginBottom: 7,
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Recuperare parolă
            </Typography>
            <Typography>
              Nu vă faceți griji, suntem aici să vă ajutăm!
            </Typography>
            <Typography>
              Apăsați butonul Trimite și veți primi pe e-mail un link pentru
              resetarea parolei.
            </Typography>
          </Box>
          <form onSubmit={formikSendEmail.handleSubmit}>
            <Stack
              alignItems="flex-start"
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: {
                  xs: 3,
                  sm: 0,
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Adresa de e-mail"
                  variant="filled"
                  value={formikSendEmail.values.email}
                  onChange={formikSendEmail.handleChange}
                  onBlur={formikSendEmail.handleBlur}
                  error={
                    formikSendEmail.touched.email &&
                    Boolean(formikSendEmail.errors.email)
                  }
                  helperText={
                    formikSendEmail.touched.email &&
                    formikSendEmail.errors.email
                  }
                  sx={{
                    "& input": {
                      padding: "0.75rem 4.25rem 0.25rem 1rem",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: {
                    xs: 1,
                    sm: 0,
                  },
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                  marginLeft: {
                    xs: 0,
                    sm: -7.5,
                  },
                }}
              >
                <Button
                  disabled={loading}
                  variant="contained"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  Trimite
                </Button>
              </Box>
            </Stack>
            <FormErrorMessage>{error}</FormErrorMessage>
          </form>
        </>
      )}
      {currentStep === "reset-password-ok" && (
        <>
          <Box
            sx={{
              marginBottom: 7,
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Verificați e-mailul
            </Typography>
            <Typography>Mai aveți încă un pas!</Typography>
            <Typography>
              Am trimis linkul de resetare a parolei către adresa{" "}
              {formikSendEmail.values.email}.
            </Typography>
            <Typography>Vă rugăm să verificați și în folderul Spam.</Typography>
          </Box>
        </>
      )}
      <Box
        marginTop={4}
        sx={{
          textAlign: {
            xs: "center",
            sm: "left",
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowLongLeftIcon width={20} />}
          to="/login"
          sx={{ marginBottom: 2.5 }}
          component={RouterLink}
        >
          Înapoi la Autentificare
        </Button>
      </Box>
    </div>
  );
};

export default RecoverPasswordForm;
