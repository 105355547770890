import React, { useEffect } from "react";
import SignUpForm from "components/sign-up/SignUpForm";
import { CheckUserAuthenticated } from "components/common";
const SignUp = ({ contentRef }) => {
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckUserAuthenticated>
      <SignUpForm />
    </CheckUserAuthenticated>
  );
};

export default SignUp;
