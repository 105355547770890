import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Button, Typography, Box, Grid, Stack } from "@mui/material";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
// import theme from "utils/theme";
import {
  // LogoAZnextGen,
  PlanetAnimation,
} from "components/common";

const Error404 = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        flexGrow={1}
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Stack gap={4}>
            <Typography
              color="warning.darker"
              sx={{
                fontSize: { xs: "2rem", sm: "4rem" },
                lineHeight: 1,
                fontWeight: 500,
                marginBottom: "0 !important",
              }}
            >
              404
            </Typography>
            <Typography sx={{ fontSize: "1.25rem", lineHeight: 1.3 }}>
              Ați ajuns într-o dimensiune necunoscută.
              <br />
              Pagina nu a putut fi găsită.
            </Typography>
            <Box>
              <Button
                component={RouterLink}
                startIcon={<ArrowLongLeftIcon width={20} />}
                variant="contained"
                to={"/"}
              >
                Înapoi
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignSelf: "center",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box width="90%">
            <PlanetAnimation showQuestion />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Error404;
