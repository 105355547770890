import React, { useContext } from "react";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import { Container, Button, Typography, Box, Grid, Stack } from "@mui/material";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/24/outline";
import theme from "utils/theme";
import { motion } from "framer-motion/dist/framer-motion";
import { GetContentTherapeuticAreas, GetArticles } from "utils/api/getContent";
import {
  Icon,
  NiceBox,
  EventBox,
  ArticleBox,
  VideoBox,
} from "components/common";
import appContext from "utils/context";
import isFutureDate from "utils/isFutureDate";
import { articleContentSanitized } from "utils/content";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Error404 from "pages/Error404";
import useUserTracking from "utils/useTracking";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

const TherapeuticAreaIndicationInfo = () => {
  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();

  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const { serverTime } = useContext(appContext);
  const { therapeuticAreaSlug, therapeuticAreaIndicationSlug } = useParams();

  useUserTracking({
    page: therapeuticAreaIndicationSlug,
    therapeutic_area: therapeuticAreaSlug,
    therapeutic_area_indication: therapeuticAreaIndicationSlug,
  });

  const therapeuticArea = therapeuticAreasData
    ? therapeuticAreasData[therapeuticAreaSlug]
    : null;
  const therapeuticAreaIndication =
    therapeuticArea?.indications.find(
      (indication) => indication.slug === therapeuticAreaIndicationSlug
    ) || null;
  const navigate = useNavigate();

  const therapeuticAreaIndicationEvent =
    therapeuticAreasContent?.events?.filter(
      (event) =>
        isFutureDate(event.date, serverTime) &&
        event.therapeutic_area_indication === therapeuticAreaIndicationSlug
    )[0] || null;

  const therapeuticAreaIndicationArticles =
    therapeuticAreasContent?.articles
      .filter(
        (article) =>
          article.therapeutic_area_indication ===
            therapeuticAreaIndicationSlug &&
          (article.type === "text" || article.type === "material")
      )
      .filter((_, index) => index < 3) || [];

  const therapeuticAreaIndicationVideos =
    therapeuticAreasContent?.articles
      .filter(
        (article) =>
          article.therapeutic_area_indication ===
            therapeuticAreaIndicationSlug && article.type === "video"
      )
      .filter((_, index) => index < 3) || [];

  const ProductBox = ({ product }) => {
    return (
      <NiceBox
        onClick={() =>
          navigate(
            `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${product.slug}`
          )
        }
        colors={{
          boxShadowColor: therapeuticArea.boxShadowColor,
          borderColor: therapeuticArea.hover.borderColor,
          borderImage: therapeuticArea.color,
        }}
        minHeight={380}
      >
        <Stack rowGap={0} width="100%" height="100%" alignItems="center">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 130, paddingTop: 2 }}
          >
            <Box
              sx={{
                maxWidth: 180,
                height: 70,
              }}
            >
              <Icon
                name={`/assets/images/products/white/${product.slug}`}
                fullPath={true}
                width="100%"
                height="100%"
                objectFit="contain"
                className={`product-logo-${product.slug}`}
              />
            </Box>
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              paddingX: 3,
              textAlign: "center",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{
                flexGrow: 1,
                height: "100%",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "primary.darker",
                  lineHeight: 1.5,
                  "p:last-child": {
                    marginBottom: 0,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: articleContentSanitized(product.description),
                }}
              />
              {product.approval_code_description && (
                <Typography
                  variant="body3"
                  sx={{
                    color: "primary.darker",
                    marginTop: 1,
                    marginBottom: 0,
                  }}
                >
                  {product.approval_code_description}
                </Typography>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              textAlign: "center",
              paddingBottom: 4,
            }}
          >
            <Box
              className="button-more"
              sx={{
                opacity: { xs: 1, lg: 0 },
                transition: "all 200ms ease-in-out",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                endIcon={
                  <ArrowLongRightIcon width={24} style={{ color: "primary" }} />
                }
                sx={{
                  padding: theme.spacing(0.5, 2),
                  fontSize: "1rem",
                }}
              >
                Vezi
              </Button>
            </Box>
          </Box>
        </Stack>
      </NiceBox>
    );
  };
  const ProductsGrid = () => {
    return (
      <Stack
        direction="row"
        gap={{ xs: 5, lg: 8 }}
        alignItems="stretch"
        flexWrap="wrap"
        marginBottom={6}
      >
        {therapeuticAreaIndication.brands.map((product) => (
          <Box
            key={product.slug}
            sx={{
              width: "100%",
              maxWidth: {
                xs: "100%",
                sm: "calc(50% - 20px)",
                md: "calc(33% - 24px)",
                lg: "calc(25% - 24px)",
              },
              "&:hover": {
                ".button-more": {
                  opacity: 1,
                },
                [`.product-logo-${product.slug}`]: {
                  transform: "scale(1.1)",
                },
              },
            }}
          >
            <ProductBox product={product} />
          </Box>
        ))}
        {therapeuticAreaIndication.brands.length === 0 && (
          <Box>
            Ne pare rău, momentan nu există conținut în această indicație.
          </Box>
        )}
      </Stack>
    );
  };

  const ProductsSwiper = () => {
    return (
      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        // loopAddBlankSlides={true}
        centeredSlides={true}
        centeredSlidesBounds={false}
        loop={true}
        pagination={{
          clickable: true,
          bulletClass: "swiper-bullet",
          bulletActiveClass: "swiper-bullet-active",
        }}
        modules={[Autoplay, Pagination]}
      >
        {therapeuticAreaIndication.brands.map((product) => (
          <SwiperSlide key={product.slug}>
            <Box marginBottom={6} paddingX={2}>
              <ProductBox product={product} />
            </Box>
          </SwiperSlide>
        ))}
        {therapeuticAreaIndication.brands.length === 0 && (
          <SwiperSlide>
            <Box marginBottom={6} paddingX={2} textAlign="center">
              Ne pare rău, momentan nu există conținut în această indicație.
            </Box>
          </SwiperSlide>
        )}
      </Swiper>
    );
  };

  return therapeuticAreasData &&
    therapeuticAreasContent &&
    therapeuticAreaIndication !== null ? (
    therapeuticAreaIndication ? (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: 280,
            background:
              "linear-gradient(90deg, rgba(111, 30, 154, 0.32) 1.56%, rgba(184, 0, 98, 0.32) 100%)",
            filter: "blur(160px)",
          }}
        />
        <Container
          maxWidth="xl"
          sx={{ paddingTop: 2.5, paddingBottom: 20, position: "relative" }}
        >
          <Box marginBottom={5} textAlign={{ xs: "center", sm: "left" }}>
            <Button
              component={RouterLink}
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              to={`/therapeutic-areas/${therapeuticAreaSlug}`}
              sx={{ fontSize: "1rem" }}
            >
              Înapoi la {therapeuticArea.title}
            </Button>
          </Box>
          <Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
              color: therapeuticArea.colorText,
              marginBottom: {
                xs: 6,
                sm: 10,
              },
              justifyContent: {
                xs: "center",
                sm: "start",
              },
            }}
          >
            <Grid item md={5} lg={6}>
              <Stack
                direction="row"
                columnGap={{
                  xs: 1,
                  sm: 2,
                }}
                alignItems="center"
              >
                <Box
                  sx={{
                    width: {
                      xs: 24,
                      sm: 56,
                    },
                    height: {
                      xs: 24,
                      sm: 56,
                    },
                    backgroundColor: therapeuticArea.colorText,
                    maskImage: `url(/assets/images/icons/indicatii/white/${therapeuticAreaIndication.slug}.svg)`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                  }}
                >
                  {/* <Icon
                  name={`indicatii/gradient/${therapeuticAreaIndication.slug}`}
                  width={56}
                /> */}
                </Box>
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "1.25rem",
                        sm: "2.5rem",
                        md: "3rem",
                        lg: "4rem",
                      },
                      marginBottom: 0,
                    }}
                  >
                    {therapeuticAreaIndication.title}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={7} lg={6}>
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  letterSpacing: "-0.02188rem",
                  fontSize: {
                    sm: "1rem",
                  },
                  textAlign: {
                    xs: "center",
                    sm: "left",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: articleContentSanitized(
                    therapeuticAreaIndication.description
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box
            display={{ xs: "block", sm: "none" }}
            marginX={-2}
            marginBottom={4}
          >
            <ProductsSwiper />
          </Box>
          <Box display={{ xs: "none", sm: "block" }}>
            <ProductsGrid />
          </Box>

          <Grid container spacing={6}>
            {therapeuticAreaIndicationEvent && (
              <Grid item xs={12} md={5} lg={4}>
                <Typography variant="h2">Evenimente viitoare</Typography>
                <EventBox event={therapeuticAreaIndicationEvent} />
              </Grid>
            )}
            {therapeuticAreaIndicationArticles.length > 0 && (
              <Grid
                item
                xs={12}
                md={therapeuticAreaIndicationEvent ? 7 : 12}
                lg={8}
              >
                <Typography variant="h2">Articole</Typography>
                <Grid container spacing={4} alignItems="start">
                  {therapeuticAreaIndicationArticles.map((article, index) => (
                    <Grid item xs={12} sm={6} md={4} key={`article-${index}`}>
                      <ArticleBox article={article} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {therapeuticAreaIndicationVideos.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h2">Video</Typography>
                <Grid container spacing={4} alignItems="start">
                  {therapeuticAreaIndicationVideos.map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} key={`video-${index}`}>
                      <VideoBox video={video} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default TherapeuticAreaIndicationInfo;
