import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import { GetContentTherapeuticAreas } from "utils/api/getContent";

const Animation = () => {
  const navigate = useNavigate();
  const { data: therapeuticAreasData } = GetContentTherapeuticAreas();

  const therapeuticAreas = therapeuticAreasData
    ? Object.values(therapeuticAreasData)
    : [];

  // console.log(therapeuticAreas);

  return (
    therapeuticAreas && (
      <Box paddingTop={4} paddingBottom={8}>
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={100}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
        >
          {therapeuticAreas.map((therapeuticArea, index) => (
            <SwiperSlide key={`therapeutic-area-${index}`}>
              <Box
                textAlign="center"
                sx={{ cursor: "pointer", paddingBottom: "30px" }}
                onClick={() => {
                  navigate(
                    `/therapeutic-areas/${therapeuticAreas[index].slug}`
                  );
                }}
              >
                <img
                  src={therapeuticArea.image}
                  height={220 * 0.75}
                  alt={therapeuticArea.title}
                />
                <Typography
                  variant="h1"
                  style={{
                    // marginTop: theme.spacing(1),
                    background: therapeuticArea.color,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    lineHeight: 1.5,
                  }}
                  component="div"
                >
                  {therapeuticArea.title}
                </Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    )
  );
};

export default Animation;
