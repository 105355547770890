import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const IconButtonOutlined = styled(IconButton)(({ theme }) => ({
  position: "relative",
  border: "0.5px solid rgba(255, 255, 255, 0.25)",
  background:
    "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
  "&:hover": {
    boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
    backdropFilter: "blur(16px)",
  },
}));

// const IconButtonOutlined = ({ type = "button", onClick, to, children }) => {
//   return type === "button" ? (
//     <IconButtonCustom onClick={onClick}>{children}</IconButtonCustom>
//   ) : type === "router-link" ? (
//     <IconButtonCustom component={RouterLink} to={to}>
//       {children}
//     </IconButtonCustom>
//   ) : null;
// };

export default IconButtonOutlined;
